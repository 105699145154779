import { run } from 'googleapis/build/src/apis/run';
import {
  AnnotationData,
  BarGraphData,
  DataSeries,
  FootplantSeriesData,
  FootplantSeries_Left,
  FootplantSeries_Right,
  GraphSeriesData,
  JointCouplingSeriesData,
  KinematicData,
  RearPendulumGraphData,
  RunHamstringFunctionData,
  RunKinematicData,
  RunReportData,
  RunVariabilityData,
  RunningStatusData,
  ScissorsData,
  ScissorsTableData,
  VariabilityGraphData,
  attractorLabelsV1,
  attractorLabelsV2,
} from '../../model/reportV2.model';
import {
  colorCS,
  colorLeft,
  colorRight,
  dangerColor,
  variabilityAll_Left,
  variabilityAll_Right,
  variabilityMean_Left,
  variabilityMean_Right,
} from '../../theme/colors';
import {
  transpose,
  defineAttractorLabels,
  toJointCouplingData,
  flattenData,
  calculateRearPendulumGraphData,
  getfootplantCoordinates,
  toGraphData,
  toScissorsData,
  calculateXaxisJointCouplingRange,
  toVariabilityData,
  toLineGraphDataStrides,
  calculateYaxisRange,
  calculateSymmetry,
  getAllRunKinematicData,
  getKneeVariabilityData,
  getAnkleVariabilityData,
  getPelvisVariabilityData,
  createVariabilityLegend,
  createKinematicGraphsAnnotations,
  createVariabilitySingleSideGraphAnnotations,
  createEuclideanGraphAnnotations,
  createEuclideanGraphData,
  createHamstringFunctionData,
} from './dataFunctions';
import { create } from 'ionicons/icons';
// import { createVariabilitySingleSideGraphAnnotations } from './dataFunctions/defineVariabilitySingleSideGraphAnnotations';

export const createRunReportData = (data: any): RunReportData => {
  const Majorversion = parseInt(data.reportId.version.col0.join(''));
  const Minorversion = parseInt(data.reportId.version.col1.join(''));
  const Patchversion = parseInt(data.reportId.version.col2.join(''));
  const version = `${Majorversion}.${Minorversion}.${Patchversion}`;
  const isAboveVersion =
    Majorversion > 2 ||
    (Majorversion === 2 && Minorversion > 1) ||
    (Majorversion === 2 && Minorversion === 1 && Patchversion >= 10);
  const CanShowHamstringFunctionData =
    Majorversion > 2 ||
    (Majorversion === 2 && Minorversion > 1) ||
    (Majorversion === 2 && Minorversion === 1 && Patchversion >= 11);

  const annotations_Left: number[] = data?.reportId.pointsOfInterests.col0;
  const annotations_Right: number[] = data?.reportId.pointsOfInterests.col1;

  //#region Attractors Page
  const attractorData: number[][] = transpose(data?.reportId.attractors);
  const attractorLabels = isAboveVersion
    ? defineAttractorLabels(data.reportId.attractors, attractorLabelsV2)
    : defineAttractorLabels(data.reportId.attractors, attractorLabelsV1);

  const runAttractorData = {
    attractorData,
    attractorLabels,
  };
  //#endregion

  //#region Running Status
  const runningStatusTableData: number[][] = transpose(data.reportId.runningStatusTableData);
  const romData: number[][] = transpose(data.reportId.rom);
  const annotations: number[] = data?.reportId.pointsOfInterests.col0;

  const {
    rearPendulumGraphData_Left,
    rearPendulum_ToeOff_Left,
    rearPendulum_MidSwing_Left,
    rearPendulumStance_Left,
    rearPendulumVariance_ToeOff_Left,
    rearPendulumVariance_MidSwing_Left,
    rearPendulumGraphData_Right,
    rearPendulum_ToeOff_Right,
    rearPendulum_MidSwing_Right,
    rearPendulumStance_Right,
    rearPendulumVariance_ToeOff_Right,
    rearPendulumVariance_MidSwing_Right,
    rearPendulumZoomModalContent,
  } = calculateRearPendulumGraphData(data);

  const rearPendulumData: RearPendulumGraphData = {
    rearPendulumGraphData_Left,
    rearPendulum_ToeOff_Left,
    rearPendulum_MidSwing_Left,
    rearPendulumStance_Left,
    rearPendulumVariance_ToeOff_Left,
    rearPendulumVariance_MidSwing_Left,
    rearPendulumGraphData_Right,
    rearPendulum_ToeOff_Right,
    rearPendulum_MidSwing_Right,
    rearPendulumStance_Right,
    rearPendulumVariance_ToeOff_Right,
    rearPendulumVariance_MidSwing_Right,
    rearPendulumZoomModalContent,
  };

  const footplantData_InitialContact_Left = getfootplantCoordinates(data.reportId.projectionsFootPlant_Left, 0);
  const footplantData_InitialContact_Right = getfootplantCoordinates(data.reportId.projectionsFootPlant_Right, 0);
  const footplantData_MidStance_Left = getfootplantCoordinates(data.reportId.projectionsFootPlant_Left, 1);
  const footplantData_MidStance_Right = getfootplantCoordinates(data.reportId.projectionsFootPlant_Right, 1);
  const footplantData_HeelOff_Left = getfootplantCoordinates(data.reportId.projectionsFootPlant_Left, 2);
  const footplantData_HeelOff_Right = getfootplantCoordinates(data.reportId.projectionsFootPlant_Right, 2);

  const footplantAtInitialContactSeries_Left: FootplantSeriesData[] = [
    {
      type: 'scatter',
      name: 'IC - Left',
      data: footplantData_InitialContact_Left,
    },
  ];

  const footplantAtMidStanceSeries_Left: FootplantSeriesData[] = [
    {
      type: 'scatter',
      name: 'MSt - Left',
      data: footplantData_MidStance_Left,
    },
  ];

  const footplantAtHeelOffSeries_Left: FootplantSeriesData[] = [
    {
      type: 'scatter',
      name: 'HO - Left',
      data: footplantData_HeelOff_Left,
    },
  ];

  const footplantAtInitialContactSeries_Right: FootplantSeriesData[] = [
    {
      type: 'scatter',
      name: 'IC - Right',
      data: footplantData_InitialContact_Right,
    },
  ];

  const footplantAtMidStanceSeries_Right: FootplantSeriesData[] = [
    {
      type: 'scatter',
      name: 'MSt - Right',
      data: footplantData_MidStance_Right,
    },
  ];

  const footplantAtHeelOffSeries_Right: FootplantSeriesData[] = [
    {
      type: 'scatter',
      name: 'HO - Right',
      data: footplantData_HeelOff_Right,
    },
  ];

  const footplantSeries_Left: FootplantSeries_Left = {
    footplantAtInitialContactSeries_Left,
    footplantAtMidStanceSeries_Left,
    footplantAtHeelOffSeries_Left,
  };

  const footplantSeries_Right: FootplantSeries_Right = {
    footplantAtInitialContactSeries_Right,
    footplantAtMidStanceSeries_Right,
    footplantAtHeelOffSeries_Right,
  };

  const leftAnklePositionsSeriesData = toGraphData(data?.stridesLeft.respectTheSagittalPlaneAnkle_Left);
  const rightAnklePositionsSeriesData = toGraphData(data?.stridesRight.respectTheSagittalPlaneAnkle_Right);

  const leftAnklePositionsSeries: GraphSeriesData[] = [
    {
      type: 'line',
      name: 'Left Ankle',
      data: flattenData(leftAnklePositionsSeriesData),
      color: colorLeft,
    },
  ];

  const rightAnklePositionsSeries: GraphSeriesData[] = [
    {
      type: 'line',
      name: 'Right Ankle',
      data: flattenData(rightAnklePositionsSeriesData),
      color: colorRight,
    },
  ];

  const respectTheSagittalPlaneSeries = [...leftAnklePositionsSeries, ...rightAnklePositionsSeries];

  const runningStatusData: RunningStatusData = {
    runningStatusTableData,
    romData,
    rearPendulumData,
    footplantSeries_Left,
    footplantSeries_Right,
    respectTheSagittalPlaneSeries,
    annotations,
  };
  //#endregion

  //#region Scissors
  const colorsScissorsLeft: string[] = [colorRight, colorLeft, dangerColor]; // green, blue, red
  const colorsScissorsRight: string[] = [colorLeft, colorRight, dangerColor]; // blue, green, red
  const yAxisLabelVelocity: string = isAboveVersion ? 'm/s' : 'deg/s';
  const yAxisLabelAcceleration: string = isAboveVersion ? 'm/s²' : 'deg/s²';

  //#region ScissorsData
  const rightThigh_LeftStrides = toGraphData(data?.stridesLeft.rightThigh_LeftStrides);
  const leftThigh = toGraphData(data?.stridesLeft.leftThigh);
  const leftScissorsMoments = toScissorsData(data?.reportId.scissors_Left);
  const seriesLeft_Contra: GraphSeriesData[] = [
    { type: 'line', name: 'Contralateral leg', data: flattenData(rightThigh_LeftStrides), color: colorRight },
  ];
  const seriesLeft: GraphSeriesData[] = [
    { type: 'line', name: 'Left leg', data: flattenData(leftThigh), color: colorLeft },
  ];
  const seriesLeftScissor: GraphSeriesData[] = [
    { type: 'scatter', name: 'Scissors moment', data: flattenData(leftScissorsMoments), color: colorCS },
  ];
  const scissorsLeftGraphData: GraphSeriesData[] = [...seriesLeft_Contra, ...seriesLeft, ...seriesLeftScissor];

  const leftThigh_RightStrides = toGraphData(data?.stridesRight.leftThigh_RightStrides);
  const rightThigh = toGraphData(data?.stridesRight.rightThigh);
  const rightScissorsMoments = toScissorsData(data?.reportId.scissors_Right);

  const seriesRight_Contra: GraphSeriesData[] = [
    { type: 'line', name: 'Contralateral leg', data: flattenData(leftThigh_RightStrides), color: colorLeft },
  ];
  const seriesRight: GraphSeriesData[] = [
    { type: 'line', name: 'Right leg', data: flattenData(rightThigh), color: colorRight },
  ];
  const seriesRightScissor: GraphSeriesData[] = [
    { type: 'scatter', name: 'Scissors moment', data: flattenData(rightScissorsMoments), color: colorCS },
  ];

  const scissorsRightGraphData: GraphSeriesData[] = [...seriesRight_Contra, ...seriesRight, ...seriesRightScissor];
  const firstHighlightMarker_Left: string = data?.reportId.pointsOfInterests.col0[3];
  const secondHighlightMarker_Left: string = data?.reportId.pointsOfInterests.col0[4];
  const firstHighlightMarker_Right: string = data?.reportId.pointsOfInterests.col1[3];
  const secondHighlightMarker_Right: string = data?.reportId.pointsOfInterests.col1[4];
  const scissorsMatrix = data?.reportId.scissorsMatrix;
  const scissorsBarGraphData = [{ data: [scissorsMatrix.col0[0], scissorsMatrix.col1[0]] }];

  const scissorsData_Left: ScissorsData = {
    graphData: scissorsLeftGraphData,
    colors: colorsScissorsLeft,
    firstMarker: firstHighlightMarker_Left,
    secondMarker: secondHighlightMarker_Left,
  };

  const scissorsData_Right: ScissorsData = {
    graphData: scissorsRightGraphData,
    colors: colorsScissorsRight,
    firstMarker: firstHighlightMarker_Right,
    secondMarker: secondHighlightMarker_Right,
  };
  //#endregion

  //#region JointCoupling
  const leftKneeAngles = data.stridesLeft.leftKnee;
  const leftHipAngles = data.stridesLeft.leftHip;
  const seriesJointCouplingData_Left = toJointCouplingData(leftKneeAngles!, leftHipAngles!);
  const heelOff_Left = data.reportId.pointsOfInterests.col0[0];
  const toeOff_Left = data.reportId.pointsOfInterests.col0[1];
  const midSwing_Left = data.reportId.pointsOfInterests.col0[2];
  const terminalSwing_Left = data.reportId.pointsOfInterests.col0[5];
  const initialContact_Left = data.reportId.pointsOfInterests.col0[3];
  const midStance_Left = data.reportId.pointsOfInterests.col0[4];
  const meanLeftKneeAngle = data.reportId.kinematicData.col3;
  const meanLeftHipAngle = data.reportId.kinematicData.col1;
  const pointsOfInterestJointCouplingData_Left: JointCouplingSeriesData[] = [
    {
      type: 'scatter',
      name: 'Points of Interest',
      data: [
        {
          x: meanLeftHipAngle[heelOff_Left],
          y: meanLeftKneeAngle[heelOff_Left],
        },
        {
          x: meanLeftHipAngle[toeOff_Left],
          y: meanLeftKneeAngle[toeOff_Left],
        },
        {
          x: meanLeftHipAngle[midSwing_Left],
          y: meanLeftKneeAngle[midSwing_Left],
        },
        {
          x: meanLeftHipAngle[terminalSwing_Left],
          y: meanLeftKneeAngle[terminalSwing_Left],
        },
        {
          x: meanLeftHipAngle[initialContact_Left],
          y: meanLeftKneeAngle[initialContact_Left],
        },
        {
          x: meanLeftHipAngle[midStance_Left],
          y: meanLeftKneeAngle[midStance_Left],
        },
      ],
    },
  ];

  const seriesJointCoupling_Left: JointCouplingSeriesData[] = [
    ...seriesJointCouplingData_Left,
    ...pointsOfInterestJointCouplingData_Left,
  ];

  const rightKneeAngles = data?.stridesRight.rightKnee;
  const rightHipAngles = data?.stridesRight.rightHip;
  const seriesJointCouplingData_Right = toJointCouplingData(rightKneeAngles!, rightHipAngles!);
  const heelOff_Right = data.reportId.pointsOfInterests.col1[0];
  const toeOff_Right = data.reportId.pointsOfInterests.col1[1];
  const midSwing_Right = data.reportId.pointsOfInterests.col1[2];
  const terminalSwing_Right = data.reportId.pointsOfInterests.col1[5];
  const initialContact_Right = data.reportId.pointsOfInterests.col1[3];
  const midStance_Right = data.reportId.pointsOfInterests.col1[4];
  const meanRightKneeAngle = data.reportId.kinematicData.col4;
  const meanRightHipAngle = data.reportId.kinematicData.col2;

  const pointsOfInterestJointCouplingData_Right: JointCouplingSeriesData[] = [
    {
      type: 'scatter',
      name: 'Points of Interest',
      data: [
        {
          x: meanRightHipAngle[heelOff_Right],
          y: meanRightKneeAngle[heelOff_Right],
        },
        {
          x: meanRightHipAngle[toeOff_Right],
          y: meanRightKneeAngle[toeOff_Right],
        },
        {
          x: meanRightHipAngle[midSwing_Right],
          y: meanRightKneeAngle[midSwing_Right],
        },
        {
          x: meanRightHipAngle[terminalSwing_Right],
          y: meanRightKneeAngle[terminalSwing_Right],
        },
        {
          x: meanRightHipAngle[initialContact_Right],
          y: meanRightKneeAngle[initialContact_Right],
        },
        {
          x: meanRightHipAngle[midStance_Right],
          y: meanRightKneeAngle[midStance_Right],
        },
      ],
    },
  ];

  const seriesJointCoupling_Right: JointCouplingSeriesData[] = [
    ...seriesJointCouplingData_Right,
    ...pointsOfInterestJointCouplingData_Right,
  ];
  const { xMinValue, xMaxValue, xTickAmount } = calculateXaxisJointCouplingRange(
    seriesJointCouplingData_Left,
    seriesJointCouplingData_Right,
  );

  const jointCouplingZoomModalContent = {
    seriesJointCoupling_Left,
    seriesJointCoupling_Right,
  };

  const jointCouplingAxisRange = {
    xMinValue,
    xMaxValue,
    xTickAmount,
  };

  const runJointCouplingData = {
    seriesJointCoupling_Left,
    seriesJointCoupling_Right,
    jointCouplingAxisRange,
    jointCouplingZoomModalContent,
  };
  //#endregion

  //#region Hamstring data
  const thighVelocityBarData: BarGraphData = [
    {
      name: 'Left',
      data: [
        data?.reportId.thighVelocity.col0[0],
        data?.reportId.thighVelocity.col0[1],
        data?.reportId.thighVelocity.col0[2],
        data?.reportId.thighVelocity.col0[3],
      ],
      color: colorLeft,
    },
    {
      name: 'Right',
      data: [
        data?.reportId.thighVelocity.col1[0],
        data?.reportId.thighVelocity.col1[1],
        data?.reportId.thighVelocity.col1[2],
        data?.reportId.thighVelocity.col1[3],
      ],
      color: colorRight,
    },
  ];

  const thighVelocityBarData_std = data?.reportId.thighVelocity_Std;
  const thighAccelerationBarData_std = data?.reportId.thighAcceleration_Std;
  const thighAccelerationBarData: BarGraphData = [
    {
      name: 'Left',
      data: [data?.reportId.thighAcceleration.col0[0], data?.reportId.thighAcceleration.col0[1]],
      color: colorLeft,
    },
    {
      name: 'Right',
      data: [data?.reportId.thighAcceleration.col1[0], data?.reportId.thighAcceleration.col1[1]],
      color: colorRight,
    },
  ];

  const hamstringData = {
    thighVelocityBarData,
    thighVelocityBarData_std,
    thighAccelerationBarData_std,
    thighAccelerationBarData,
    yAxisLabelAcceleration,
    yAxisLabelVelocity,
  };

  //#endregion

  //#region Coordinative Variability
  const coordinativeVariabilityDataAll_Left: DataSeries = toVariabilityData(
    data?.coordinativeVariabilityStance.coordinativeVariabilityHip_Left_Stance.col0,
  );
  const coordinativeVariabilityDataMean_Left: DataSeries = toVariabilityData(
    data?.coordinativeVariabilityStance.coordinativeVariabilityHip_Left_Stance.col2,
  );
  const coordinativeVariabilitySeriesAll_Left: GraphSeriesData[] = [
    { type: 'area', name: 'All strides', data: coordinativeVariabilityDataAll_Left, color: variabilityAll_Left },
  ];
  const coordinativeVariabilitySeriesMean_Left: GraphSeriesData[] = [
    { type: 'area', name: 'Based on mean', data: coordinativeVariabilityDataMean_Left, color: variabilityMean_Left },
  ];
  const coordinativeVariabilitySeries_Left: GraphSeriesData[] = [
    ...coordinativeVariabilitySeriesAll_Left,
    ...coordinativeVariabilitySeriesMean_Left,
  ];
  const ICAnnotation_Left: AnnotationData = { title: 'IC', x: data?.reportId.pointsOfInterest_Stance.col0[0] };
  const MStAnnotation_Left: AnnotationData = {
    title: 'MSt',
    x: data?.reportId.pointsOfInterest_Stance.col0[1],
  };
  const HOAnnotation_Left: AnnotationData = {
    title: 'HO',
    x: data?.reportId.pointsOfInterest_Stance.col0[2],
  };
  const TOAnnotation_Left: AnnotationData = {
    title: 'TO',
    x: coordinativeVariabilityDataAll_Left.length - 1,
  };

  const coordinativeVariabilityData_Left = {
    series: coordinativeVariabilitySeries_Left,
    annotations: { ICAnnotation_Left, MStAnnotation_Left, HOAnnotation_Left, TOAnnotation_Left },
  };

  const coordinativeVariabilityDataAll_Right: DataSeries = toVariabilityData(
    data?.coordinativeVariabilityStance.coordinativeVariabilityHip_Right_Stance.col0,
  );
  const coordinativeVariabilityDataMean_Right: DataSeries = toVariabilityData(
    data?.coordinativeVariabilityStance.coordinativeVariabilityHip_Right_Stance.col2,
  );

  const coordinativeVariabilitySeriesAll_Right: GraphSeriesData[] = [
    { type: 'area', name: 'All strides', data: coordinativeVariabilityDataAll_Right, color: variabilityAll_Right },
  ];
  const coordinativeVariabilitySeriesMean_Right: GraphSeriesData[] = [
    { type: 'area', name: 'Based on mean', data: coordinativeVariabilityDataMean_Right, color: variabilityMean_Right },
  ];

  const coordinativeVariabilitySeries_Right: GraphSeriesData[] = [
    ...coordinativeVariabilitySeriesAll_Right,
    ...coordinativeVariabilitySeriesMean_Right,
  ];

  const ICAnnotation_Right: AnnotationData = { title: 'IC', x: data?.reportId.pointsOfInterest_Stance.col1[0] };

  const MStAnnotation_Right: AnnotationData = {
    title: 'MSt',
    x: data?.reportId.pointsOfInterest_Stance.col1[1],
  };

  const HOAnnotation_Right: AnnotationData = {
    title: 'HO',
    x: data?.reportId.pointsOfInterest_Stance.col1[2],
  };

  const TOAnnotation_Right: AnnotationData = {
    title: 'TO',
    x: coordinativeVariabilityDataAll_Right.length - 1,
  };

  const coordinativeVariabilityData_Right = {
    series: coordinativeVariabilitySeries_Right,
    annotations: { ICAnnotation_Right, MStAnnotation_Right, HOAnnotation_Right, TOAnnotation_Right },
  };

  const coordinativeVariabilityData = {
    coordinativeVariabilityData_Left,
    coordinativeVariabilityData_Right,
  };

  //#endregion

  //#region Swingleg retraction
  const thighAccelerationSeriesData_Left = toLineGraphDataStrides(data?.reportId.thighAccelerationStance_Left);
  const thighAccelerationSeriesData_Right = toLineGraphDataStrides(data?.reportId.thighAccelerationStance_Right);
  const { yMinValue, yMaxValue } = calculateYaxisRange(data, isAboveVersion);

  const swingLegRetractionData = {
    thighAccelerationSeriesData_Left,
    thighAccelerationSeriesData_Right,
    yMinValue,
    yMaxValue,
  };
  //#endregion

  //#region scissorsTableData
  const scissorsTableData: ScissorsTableData = {
    scissors: {
      averageVelocity: {
        left: scissorsMatrix.col0[0].toFixed(1),
        right: scissorsMatrix.col1[0].toFixed(1),
        symmetry: calculateSymmetry(scissorsMatrix.col0[0], scissorsMatrix.col1[0], 0),
        unit: yAxisLabelVelocity,
      },
    },
    averageVelocities: {
      terminalSwing: {
        left: data?.reportId.thighVelocity.col0[1].toFixed(1),
        right: data?.reportId.thighVelocity.col1[1].toFixed(1),
        symmetry: calculateSymmetry(data?.reportId.thighVelocity.col0[1], data?.reportId.thighVelocity.col1[1], 0),
        unit: yAxisLabelVelocity,
      },
      stance: {
        left: data?.reportId.thighVelocity.col0[3].toFixed(1),
        right: data?.reportId.thighVelocity.col1[3].toFixed(1),
        symmetry: calculateSymmetry(data?.reportId.thighVelocity.col0[3], data?.reportId.thighVelocity.col1[3], 0),
        unit: yAxisLabelVelocity,
      },
      peakTerminalSwing: {
        left: data?.reportId.thighVelocity.col0[0].toFixed(1),
        right: data?.reportId.thighVelocity.col1[0].toFixed(1),
        symmetry: calculateSymmetry(data?.reportId.thighVelocity.col0[0], data?.reportId.thighVelocity.col1[0], 0),
        unit: yAxisLabelVelocity,
      },
      peakTerminalStance: {
        left: data?.reportId.thighVelocity.col0[2].toFixed(1),
        right: data?.reportId.thighVelocity.col1[2].toFixed(1),
        symmetry: calculateSymmetry(data?.reportId.thighVelocity.col0[2], data?.reportId.thighVelocity.col1[2], 0),
        unit: yAxisLabelVelocity,
      },
    },
    peakVelocitiesAndAccelerations: {
      velocity: {
        terminalSwing: {
          left: data?.reportId.thighVelocity.col0[0].toFixed(1),
          right: data?.reportId.thighVelocity.col1[0].toFixed(1),
          symmetry: calculateSymmetry(data?.reportId.thighVelocity.col0[0], data?.reportId.thighVelocity.col1[0], 0),
          unit: yAxisLabelVelocity,
        },
        stance: {
          left: data?.reportId.thighVelocity.col0[2].toFixed(1),
          right: data?.reportId.thighVelocity.col1[2].toFixed(1),
          symmetry: calculateSymmetry(data?.reportId.thighVelocity.col0[2], data?.reportId.thighVelocity.col1[2], 0),
          unit: yAxisLabelVelocity,
        },
      },
      acceleration: {
        terminalSwing: {
          left: data?.reportId.thighAcceleration.col0[0].toFixed(1),
          right: data?.reportId.thighAcceleration.col1[0].toFixed(1),
          symmetry: calculateSymmetry(
            data?.reportId.thighAcceleration.col0[0],
            data?.reportId.thighAcceleration.col1[0],
            0,
          ),
          unit: yAxisLabelAcceleration,
        },
        stance: {
          left: data?.reportId.thighAcceleration.col0[1].toFixed(1),
          right: data?.reportId.thighAcceleration.col1[1].toFixed(1),
          symmetry: calculateSymmetry(
            data?.reportId.thighAcceleration.col0[1],
            data?.reportId.thighAcceleration.col1[1],
            0,
          ),
          unit: yAxisLabelAcceleration,
        },
      },
    },
  };
  //#endregion

  const runScissorsData = {
    scissorsData_Left,
    scissorsData_Right,
    scissorsBarGraphData,
    runJointCouplingData,
    hamstringData,
    coordinativeVariabilityData,
    swingLegRetractionData,
    scissorsTableData,
  };

  //#endregion

  //#region Kinematics
  const pelvisKinematicData = getAllRunKinematicData(data, 'Pelvis');
  const hipKinematicData = getAllRunKinematicData(data, 'Hip');
  const kneeKinematicData = getAllRunKinematicData(data, 'Knee');
  const ankleKinematicData = getAllRunKinematicData(data, 'Ankle');
  const graphAnnotations = createKinematicGraphsAnnotations(annotations_Left, annotations_Right);

  const runKinematicData: RunKinematicData = {
    graphAnnotations,
    pelvisKinematicData,
    hipKinematicData,
    kneeKinematicData,
    ankleKinematicData,
  };

  //#endregion

  //#region Variability
  const highlightsHip_Left = { x: data?.reportId.pointsOfInterests.col0[4], x2: 100 };
  const highlights_Left = { x: data?.reportId.pointsOfInterests.col0[3], x2: data?.reportId.pointsOfInterests.col0[0] };
  const highlightsHip_Right = { x: data?.reportId.pointsOfInterests.col1[4], x2: 100 };
  const highlights_Right = {
    x: data?.reportId.pointsOfInterests.col1[3],
    x2: data?.reportId.pointsOfInterests.col1[0],
  };

  const graphAnnotationsHip_Left = createVariabilitySingleSideGraphAnnotations(annotations_Left, highlightsHip_Left);
  const graphAnnotationsHip_Right = createVariabilitySingleSideGraphAnnotations(annotations_Right, highlightsHip_Right);
  const graphAnnotations_Left = createVariabilitySingleSideGraphAnnotations(annotations_Left, highlights_Left);
  const graphAnnotations_Right = createVariabilitySingleSideGraphAnnotations(annotations_Right, highlights_Right);

  const {
    crpSeriesDataPelvisThighT: crpSeriesDataPelvisThighT_Left,
    meanCrpSeriesDataPelvisThigh: meanCrpSeriesDataPelvisThigh_Left,
    crpSeriesDataPelvisCThighT: crpSeriesDataPelvisCThighT_Left,
    meanCrpSeriesDataPelvisThighT: meanCrpSeriesDataPelvisThighT_Left,
    crpSeriesDataPelvisCThigh: crpSeriesDataPelvisCThigh_Left,
    meanCrpSeriesDataPelvisCThighT: meanCrpSeriesDataPelvisCThighT_Left,
    crpSeriesDataPelvisThigh: crpSeriesDataPelvisThigh_Left,
    meanCrpSeriesDataPelvisCThigh: meanCrpSeriesDataPelvisCThigh_Left,
    coordinativeVariabilitySeriesAllHip: coordinativeVariabilitySeriesAllHip_Left,
    coordinativeVariabilitySeriesMeanHip: coordinativeVariabilitySeriesMeanHip_Left,
    coordinativeVariabilitySeriesPelvisThigh: coordinativeVariabilitySeriesPelvisThigh_Left,
    coordinativeVariabilitySeriesPelvisThighT: coordinativeVariabilitySeriesPelvisThighT_Left,
    coordinativeVariabilitySeriesPelvisCThighT: coordinativeVariabilitySeriesPelvisCThighT_Left,
    coordinativeVariabilitySeriesPelvisCThigh: coordinativeVariabilitySeriesPelvisCThigh_Left,
  } = getPelvisVariabilityData(data, 'Left');

  const {
    crpSeriesDataPelvisThighT: crpSeriesDataPelvisThighT_Right,
    meanCrpSeriesDataPelvisThigh: meanCrpSeriesDataPelvisThigh_Right,
    crpSeriesDataPelvisCThighT: crpSeriesDataPelvisCThighT_Right,
    meanCrpSeriesDataPelvisThighT: meanCrpSeriesDataPelvisThighT_Right,
    crpSeriesDataPelvisCThigh: crpSeriesDataPelvisCThigh_Right,
    meanCrpSeriesDataPelvisCThighT: meanCrpSeriesDataPelvisCThighT_Right,
    crpSeriesDataPelvisThigh: crpSeriesDataPelvisThigh_Right,
    meanCrpSeriesDataPelvisCThigh: meanCrpSeriesDataPelvisCThigh_Right,
    coordinativeVariabilitySeriesAllHip: coordinativeVariabilitySeriesAllHip_Right,
    coordinativeVariabilitySeriesMeanHip: coordinativeVariabilitySeriesMeanHip_Right,
    coordinativeVariabilitySeriesPelvisThigh: coordinativeVariabilitySeriesPelvisThigh_Right,
    coordinativeVariabilitySeriesPelvisThighT: coordinativeVariabilitySeriesPelvisThighT_Right,
    coordinativeVariabilitySeriesPelvisCThighT: coordinativeVariabilitySeriesPelvisCThighT_Right,
    coordinativeVariabilitySeriesPelvisCThigh: coordinativeVariabilitySeriesPelvisCThigh_Right,
  } = getPelvisVariabilityData(data, 'Right');
  const {
    crpSeriesDataThighCalfT: crpSeriesDataThighCalfT_Left,
    meanCrpSeriesDataThighCalf: meanCrpSeriesDataThighCalf_Left,
    crpSeriesDataThighCCalfT: crpSeriesDataThighCCalfT_Left,
    meanCrpSeriesDataThighCCalfT: meanCrpSeriesDataThighCCalfT_Left,
    crpSeriesDataThighCalf: crpSeriesDataThighCalf_Left,
    meanCrpSeriesDataThighCalfT: meanCrpSeriesDataThighCalfT_Left,
    coordinativeVariabilitySeriesAllKnee: coordinativeVariabilitySeriesAllKnee_Left,
    coordinativeVariabilitySeriesMeanKnee: coordinativeVariabilitySeriesMeanKnee_Left,
    coordinativeVariabilitySeriesThighCalf: coordinativeVariabilitySeriesThighCalf_Left,
    coordinativeVariabilitySeriesThighCalfT: coordinativeVariabilitySeriesThighCalfT_Left,
    coordinativeVariabilitySeriesThighCCalfT: coordinativeVariabilitySeriesThighCCalfT_Left,
  } = getKneeVariabilityData(data, 'Left');
  const {
    crpSeriesDataThighCalfT: crpSeriesDataThighCalfT_Right,
    meanCrpSeriesDataThighCalf: meanCrpSeriesDataThighCalf_Right,
    crpSeriesDataThighCCalfT: crpSeriesDataThighCCalfT_Right,
    meanCrpSeriesDataThighCCalfT: meanCrpSeriesDataThighCCalfT_Right,
    crpSeriesDataThighCalf: crpSeriesDataThighCalf_Right,
    meanCrpSeriesDataThighCalfT: meanCrpSeriesDataThighCalfT_Right,
    coordinativeVariabilitySeriesAllKnee: coordinativeVariabilitySeriesAllKnee_Right,
    coordinativeVariabilitySeriesMeanKnee: coordinativeVariabilitySeriesMeanKnee_Right,
    coordinativeVariabilitySeriesThighCalf: coordinativeVariabilitySeriesThighCalf_Right,
    coordinativeVariabilitySeriesThighCalfT: coordinativeVariabilitySeriesThighCalfT_Right,
    coordinativeVariabilitySeriesThighCCalfT: coordinativeVariabilitySeriesThighCCalfT_Right,
  } = getKneeVariabilityData(data, 'Right');
  const {
    crpSeriesDataCalfTFootC: crpSeriesDataCalfTFootC_Left,
    meanCrpSeriesDataCalfFoot: meanCrpSeriesDataCalfFoot_Left,
    crpSeriesDataCalfFoot: crpSeriesDataCalfFoot_Left,
    meanCrpSeriesDataCalfTFootC: meanCrpSeriesDataCalfTFootC_Left,
    coordinativeVariabilitySeriesAllAnkle: coordinativeVariabilitySeriesAllAnkle_Left,
    coordinativeVariabilitySeriesMeanAnkle: coordinativeVariabilitySeriesMeanAnkle_Left,
    coordinativeVariabilitySeriesCalfFoot: coordinativeVariabilitySeriesCalfFoot_Left,
    coordinativeVariabilitySeriesCalfTFootC: coordinativeVariabilitySeriesCalfTFootC_Left,
  } = getAnkleVariabilityData(data, 'Left');
  const {
    crpSeriesDataCalfTFootC: crpSeriesDataCalfTFootC_Right,
    meanCrpSeriesDataCalfFoot: meanCrpSeriesDataCalfFoot_Right,
    crpSeriesDataCalfFoot: crpSeriesDataCalfFoot_Right,
    meanCrpSeriesDataCalfTFootC: meanCrpSeriesDataCalfTFootC_Right,
    coordinativeVariabilitySeriesAllAnkle: coordinativeVariabilitySeriesAllAnkle_Right,
    coordinativeVariabilitySeriesMeanAnkle: coordinativeVariabilitySeriesMeanAnkle_Right,
    coordinativeVariabilitySeriesCalfFoot: coordinativeVariabilitySeriesCalfFoot_Right,
    coordinativeVariabilitySeriesCalfTFootC: coordinativeVariabilitySeriesCalfTFootC_Right,
  } = getAnkleVariabilityData(data, 'Right');

  const crpSeriesPelvisThigh_Left = [
    ...crpSeriesDataPelvisThighT_Left,
    ...crpSeriesDataPelvisCThighT_Left,
    ...crpSeriesDataPelvisCThigh_Left,
    ...crpSeriesDataPelvisThigh_Left,
  ];
  const coordinativeVariabilityOverviewHip_Left = [
    ...coordinativeVariabilitySeriesAllHip_Left,
    // ...coordinativeVariabilitySeriesSelectedHip_Left,
    // ...coordinativeVariabilitySeriesMeanHip_Left,
  ];

  const crpSeriesPelvisThigh_Right = [
    ...crpSeriesDataPelvisThighT_Right,
    ...crpSeriesDataPelvisCThighT_Right,
    ...crpSeriesDataPelvisCThigh_Right,
    ...crpSeriesDataPelvisThigh_Right,
  ];

  const coordinativeVariabilityOverviewHip_Right = [
    ...coordinativeVariabilitySeriesAllHip_Right,
    // ...coordinativeVariabilitySeriesSelectedHip_Right,
    // ...coordinativeVariabilitySeriesMeanHip_Right,
  ];

  const crpSeriesThighCalf_Left = [
    ...crpSeriesDataThighCalfT_Left,
    ...crpSeriesDataThighCCalfT_Left,
    ...crpSeriesDataThighCalf_Left,
  ];

  const coordinativeVariabilityOverviewKnee_Left = [
    ...coordinativeVariabilitySeriesAllKnee_Left,
    // ...coordinativeVariabilitySeriesSelectedKnee_Left,
    // ...coordinativeVariabilitySeriesMeanKnee_Left,
  ];

  const crpSeriesThighCalf_Right = [
    ...crpSeriesDataThighCalfT_Right,
    ...crpSeriesDataThighCCalfT_Right,
    ...crpSeriesDataThighCalf_Right,
  ];

  const coordinativeVariabilityOverviewKnee_Right = [
    ...coordinativeVariabilitySeriesAllKnee_Right,
    // ...coordinativeVariabilitySeriesSelectedKnee_Right,
    // ...coordinativeVariabilitySeriesMeanKnee_Right,
  ];

  const crpSeriesCalfFoot_Left = [...crpSeriesDataCalfTFootC_Left, ...crpSeriesDataCalfFoot_Left];

  const coordinativeVariabilityOverviewAnkle_Left = [
    ...coordinativeVariabilitySeriesAllAnkle_Left,
    // ...coordinativeVariabilitySeriesSelectedAnkle_Left,
    // ...coordinativeVariabilitySeriesMeanAnkle_Left,
  ];

  const crpSeriesCalfFoot_Right = [...crpSeriesDataCalfTFootC_Right, ...crpSeriesDataCalfFoot_Right];

  const coordinativeVariabilityOverviewAnkle_Right = [
    ...coordinativeVariabilitySeriesAllAnkle_Right,
    // ...coordinativeVariabilitySeriesSelectedAnkle_Right,
    // ...coordinativeVariabilitySeriesMeanAnkle_Right,
  ];

  const meanCrpSeriesPelvisThigh_Left = [
    ...meanCrpSeriesDataPelvisThighT_Left,
    ...meanCrpSeriesDataPelvisCThighT_Left,
    ...meanCrpSeriesDataPelvisCThigh_Left,
    ...meanCrpSeriesDataPelvisThigh_Left,
  ];
  const meanCoordinativeVariabilityOverviewHip_Left = [...coordinativeVariabilitySeriesMeanHip_Left];

  const meanCrpSeriesPelvisThigh_Right = [
    ...meanCrpSeriesDataPelvisThighT_Right,
    ...meanCrpSeriesDataPelvisCThighT_Right,
    ...meanCrpSeriesDataPelvisCThigh_Right,
    ...meanCrpSeriesDataPelvisThigh_Right,
  ];

  const meanCoordinativeVariabilityOverviewHip_Right = [...coordinativeVariabilitySeriesMeanHip_Right];

  const meanCrpSeriesThighCalf_Left = [
    ...meanCrpSeriesDataThighCalfT_Left,
    ...meanCrpSeriesDataThighCCalfT_Left,
    ...meanCrpSeriesDataThighCalf_Left,
  ];

  const meanCoordinativeVariabilityOverviewKnee_Left = [...coordinativeVariabilitySeriesMeanKnee_Left];

  const meanCrpSeriesThighCalf_Right = [
    ...meanCrpSeriesDataThighCalfT_Right,
    ...meanCrpSeriesDataThighCCalfT_Right,
    ...meanCrpSeriesDataThighCalf_Right,
  ];

  const meanCoordinativeVariabilityOverviewKnee_Right = [...coordinativeVariabilitySeriesMeanKnee_Right];

  const meanCrpSeriesCalfFoot_Left = [...meanCrpSeriesDataCalfTFootC_Left, ...meanCrpSeriesDataCalfFoot_Left];

  const meanCoordinativeVariabilityOverviewAnkle_Left = [...coordinativeVariabilitySeriesMeanAnkle_Left];

  const meanCrpSeriesCalfFoot_Right = [...meanCrpSeriesDataCalfTFootC_Right, ...meanCrpSeriesDataCalfFoot_Right];

  const meanCoordinativeVariabilityOverviewAnkle_Right = [...coordinativeVariabilitySeriesMeanAnkle_Right];

  //#region Modal Data
  const crpModalSeriesHip_Left = [
    ...crpSeriesDataPelvisThigh_Left,
    ...crpSeriesDataPelvisThighT_Left,
    ...crpSeriesDataPelvisCThighT_Left,
    ...crpSeriesDataPelvisCThigh_Left,
  ];

  const coordinativeVariabilityModalSeriesHip_Left = [
    ...coordinativeVariabilitySeriesPelvisThigh_Left,
    ...coordinativeVariabilitySeriesPelvisThighT_Left,
    ...coordinativeVariabilitySeriesPelvisCThighT_Left,
    ...coordinativeVariabilitySeriesPelvisCThigh_Left,
    ...coordinativeVariabilitySeriesAllHip_Left,
  ];

  const crpModalSeriesHip_Right = [
    ...crpSeriesDataPelvisThigh_Right,
    ...crpSeriesDataPelvisThighT_Right,
    ...crpSeriesDataPelvisCThighT_Right,
    ...crpSeriesDataPelvisCThigh_Right,
  ];

  const coordinativeVariabilityModalSeriesHip_Right = [
    ...coordinativeVariabilitySeriesPelvisThigh_Right,
    ...coordinativeVariabilitySeriesPelvisThighT_Right,
    ...coordinativeVariabilitySeriesPelvisCThighT_Right,
    ...coordinativeVariabilitySeriesPelvisCThigh_Right,
    ...coordinativeVariabilitySeriesAllHip_Right,
  ];

  const crpModalSeriesKnee_Left = [
    ...crpSeriesDataThighCalf_Left,
    ...crpSeriesDataThighCalfT_Left,
    ...crpSeriesDataThighCCalfT_Left,
  ];

  const coordinativeVariabilityModalSeriesKnee_Left = [
    ...coordinativeVariabilitySeriesThighCalf_Left,
    ...coordinativeVariabilitySeriesThighCalfT_Left,
    ...coordinativeVariabilitySeriesThighCCalfT_Left,
    ...coordinativeVariabilitySeriesAllKnee_Left,
  ];

  const crpModalSeriesKnee_Right = [
    ...crpSeriesDataThighCalf_Right,
    ...crpSeriesDataThighCalfT_Right,
    ...crpSeriesDataThighCCalfT_Right,
  ];

  const coordinativeVariabilityModalSeriesKnee_Right = [
    ...coordinativeVariabilitySeriesThighCalf_Right,
    ...coordinativeVariabilitySeriesThighCalfT_Right,
    ...coordinativeVariabilitySeriesThighCCalfT_Right,
    ...coordinativeVariabilitySeriesAllKnee_Right,
  ];

  const crpModalSeriesAnkle_Left = [...crpSeriesDataCalfFoot_Left, ...crpSeriesDataCalfTFootC_Left];

  const coordinativeVariabilityModalSeriesAnkle_Left = [
    ...coordinativeVariabilitySeriesCalfFoot_Left,
    ...coordinativeVariabilitySeriesCalfTFootC_Left,
    ...coordinativeVariabilitySeriesAllAnkle_Left,
  ];

  const crpModalSeriesAnkle_Right = [...crpSeriesDataCalfFoot_Right, ...crpSeriesDataCalfTFootC_Right];

  const coordinativeVariabilityModalSeriesAnkle_Right = [
    ...coordinativeVariabilitySeriesCalfFoot_Right,
    ...coordinativeVariabilitySeriesCalfTFootC_Right,
    ...coordinativeVariabilitySeriesAllAnkle_Right,
  ];

  //#endregion

  //#region GraphData
  const mean: VariabilityGraphData = {
    Pelvis: {
      Left: {
        crpData: meanCrpSeriesPelvisThigh_Left,
        coordinativeVariabilityData: meanCoordinativeVariabilityOverviewHip_Left,
      },
      Right: {
        crpData: meanCrpSeriesPelvisThigh_Right,
        coordinativeVariabilityData: meanCoordinativeVariabilityOverviewHip_Right,
      },
    },
    Knee: {
      Left: {
        crpData: meanCrpSeriesThighCalf_Left,
        coordinativeVariabilityData: meanCoordinativeVariabilityOverviewKnee_Left,
      },
      Right: {
        crpData: meanCrpSeriesThighCalf_Right,
        coordinativeVariabilityData: meanCoordinativeVariabilityOverviewKnee_Right,
      },
    },
    Ankle: {
      Left: {
        crpData: meanCrpSeriesCalfFoot_Left,
        coordinativeVariabilityData: meanCoordinativeVariabilityOverviewAnkle_Left,
      },
      Right: {
        crpData: meanCrpSeriesCalfFoot_Right,
        coordinativeVariabilityData: meanCoordinativeVariabilityOverviewAnkle_Right,
      },
    },
  };

  const allStrides: VariabilityGraphData = {
    Pelvis: {
      Left: {
        crpData: crpSeriesPelvisThigh_Left,
        coordinativeVariabilityData: coordinativeVariabilityOverviewHip_Left,
      },
      Right: {
        crpData: crpSeriesPelvisThigh_Right,
        coordinativeVariabilityData: coordinativeVariabilityOverviewHip_Right,
      },
    },
    Knee: {
      Left: {
        crpData: crpSeriesThighCalf_Left,
        coordinativeVariabilityData: coordinativeVariabilityOverviewKnee_Left,
      },
      Right: {
        crpData: crpSeriesThighCalf_Right,
        coordinativeVariabilityData: coordinativeVariabilityOverviewKnee_Right,
      },
    },
    Ankle: {
      Left: {
        crpData: crpSeriesCalfFoot_Left,
        coordinativeVariabilityData: coordinativeVariabilityOverviewAnkle_Left,
      },
      Right: {
        crpData: crpSeriesCalfFoot_Right,
        coordinativeVariabilityData: coordinativeVariabilityOverviewAnkle_Right,
      },
    },
  };

  const modalData: VariabilityGraphData = {
    Pelvis: {
      Left: {
        crpData: crpModalSeriesHip_Left,
        coordinativeVariabilityData: coordinativeVariabilityModalSeriesHip_Left,
      },
      Right: {
        crpData: crpModalSeriesHip_Right,
        coordinativeVariabilityData: coordinativeVariabilityModalSeriesHip_Right,
      },
    },
    Knee: {
      Left: {
        crpData: crpModalSeriesKnee_Left,
        coordinativeVariabilityData: coordinativeVariabilityModalSeriesKnee_Left,
      },
      Right: {
        crpData: crpModalSeriesKnee_Right,
        coordinativeVariabilityData: coordinativeVariabilityModalSeriesKnee_Right,
      },
    },
    Ankle: {
      Left: {
        crpData: crpModalSeriesAnkle_Left,
        coordinativeVariabilityData: coordinativeVariabilityModalSeriesAnkle_Left,
      },
      Right: {
        crpData: crpModalSeriesAnkle_Right,
        coordinativeVariabilityData: coordinativeVariabilityModalSeriesAnkle_Right,
      },
    },
  };

  //#endregion

  //#region Legends
  const pelvisLegend = createVariabilityLegend(
    crpSeriesPelvisThigh_Left,
    coordinativeVariabilityOverviewHip_Left,
    coordinativeVariabilityOverviewHip_Right,
  );

  const kneeLegend = createVariabilityLegend(
    crpSeriesThighCalf_Left,
    coordinativeVariabilityOverviewKnee_Left,
    coordinativeVariabilityOverviewKnee_Right,
  );

  const ankleLegend = createVariabilityLegend(
    crpSeriesCalfFoot_Left,
    coordinativeVariabilityOverviewAnkle_Left,
    coordinativeVariabilityOverviewAnkle_Right,
  );

  //#endregion

  const runVariabilityData: RunVariabilityData = {
    annotations_Left,
    highlightsHip_Left,
    highlights_Left,
    highlights_Right,
    annotations_Right,
    highlightsHip_Right,
    graphAnnotationsHip_Left,
    graphAnnotationsHip_Right,
    graphAnnotations_Left,
    graphAnnotations_Right,
    mean,
    allStrides,
    modalData,
    pelvisLegend,
    kneeLegend,
    ankleLegend,
  };

  //#endregion

  //#region Hamstring Data
  //  Get the euclideanNorm Data from the Hip, Ankle, Knee + Annotations
  const runHamstringFunctionData = CanShowHamstringFunctionData
    ? createHamstringFunctionData(data, annotations_Left, annotations_Right, scissorsTableData)
    : null;

  // const runHamstringFunctionData = null;

  //#endregion

  // Overview Data
  const fileInfo = data.fileId;
  const reportType = data.reportId.reportType;

  const runReportData: RunReportData = {
    runAttractorData,
    runningStatusData,
    runScissorsData,
    runKinematicData,
    runVariabilityData,
    runHamstringFunctionData,
    fileInfo,
    reportType,
    version,
  };

  return runReportData;
};
