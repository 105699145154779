/** The Run Kinematics report tab */
import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  IonCard,
  IonToggle,
  IonCardContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonIcon,
  IonCardTitle,
  IonCardHeader,
} from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import '../components/Graphs/graphCard.css';
import { KinematicsGraph, KinematicsGraphV2 } from '../components/Graphs';
import { useMemo, useState } from 'react';
import { ORYXCardHeader, ORYXSmallCardHeader } from '../../../components/componentHeaders';
import { WalkKinematicData } from '../../../model/reportV2.model';
import { colorPalette } from 'ionicons/icons';
import sliders from '../../../assets/icons/sliders.svg';
import toggle from '../../../assets/icons/toggle-left.svg';
import { LegendToast } from '../../../components';

type KinematicsReportProps = RouteComponentProps & {
  tabData: WalkKinematicData;
};

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const WalkKinematicsReportTab = ({ tabData }: KinematicsReportProps) => {
  const [showAllStrides, setShowAllStrides] = useState<boolean>(false);
  const [showToast, setShowToast] = useState(false);

  const handleShowToast = () => {
    setShowToast(true);
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };

  const romData = tabData.romData;
  const tableData = tabData.tableData;

  const toggleShowAllStrides = () => {
    setShowAllStrides(!showAllStrides);
    localStorage.setItem('showAllStridesKinematics', !showAllStrides ? 'true' : 'false');
  };

  const seriesPelvis = useMemo(() => {
    return showAllStrides ? tabData.pelvisKinematicData.allStrides : tabData.pelvisKinematicData.mean;
  }, [showAllStrides, tabData]);
  const seriesHip = useMemo(() => {
    return showAllStrides ? tabData.hipKinematicData.allStrides : tabData.hipKinematicData.mean;
  }, [showAllStrides, tabData]);
  const seriesKnee = useMemo(() => {
    return showAllStrides ? tabData.kneeKinematicData.allStrides : tabData.kneeKinematicData.mean;
  }, [showAllStrides, tabData]);
  const seriesAnkle = useMemo(() => {
    return showAllStrides ? tabData.ankleKinematicData.allStrides : tabData.ankleKinematicData.mean;
  }, [showAllStrides, tabData]);

  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <IonPage>
        <IonContent className='ion-padding' color='light'>
          <IonGrid fixed>
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className=''>
                <IonCard className='' style={{ margin: 0 }}>
                  <IonCardHeader className='attractorCard_Header'>
                    <IonGrid fixed className='ion-no-padding'>
                      <IonRow className='ion-no-padding ion-align-items-center attractorCard_Header_Row '>
                        <IonCol size='4' className='ion-text-center'>
                          <IonCardTitle className='attractorCard_Title'>Sagittal</IonCardTitle>
                        </IonCol>
                        <IonCol size='4' className='ion-text-center'>
                          <IonCardTitle className='attractorCard_Title'>Frontal</IonCardTitle>
                        </IonCol>
                        <IonCol size='3' className='ion-text-center'>
                          <IonCardTitle className='attractorCard_Title'>Transversal</IonCardTitle>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonRow
                      style={{ borderBottom: '1px solid #ddd', marginBottom: '6px', backgroundColor: 'transparent' }}>
                      <IonGrid className='ion-no-padding'>
                        <IonRow className=''>
                          <IonCardTitle className='attractorCard_Title'>Pelvis</IonCardTitle>
                        </IonRow>
                        <IonRow className='ion-no-padding'>
                          <IonCol size='4' className='ion-no-padding'>
                            <KinematicsGraphV2
                              data={seriesPelvis.Sagittal.series}
                              subtitle={'Anterior tilt (-) / Posterior tilt (+)'}
                              graphAnnotations={tabData.graphAnnotations}
                              strokeWidth={showAllStrides ? 1 : 2}
                              yAxis={seriesPelvis.Sagittal.yAxis}
                            />
                          </IonCol>
                          <IonCol size='4' className='ion-no-padding'>
                            <KinematicsGraphV2
                              data={seriesPelvis.Coronal.series}
                              subtitle={'Drop (-) / Hike (+)'}
                              graphAnnotations={tabData.graphAnnotations}
                              strokeWidth={showAllStrides ? 1 : 2}
                              yAxis={seriesPelvis.Coronal.yAxis}
                            />
                          </IonCol>
                          <IonCol size='4' className='ion-no-padding'>
                            <KinematicsGraphV2
                              data={seriesPelvis.Transversal.series}
                              subtitle={'Backward Rotation (-) / Forward Rotation (+)'}
                              graphAnnotations={tabData.graphAnnotations}
                              strokeWidth={showAllStrides ? 1 : 2}
                              yAxis={seriesPelvis.Transversal.yAxis}
                            />
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonRow>

                    <IonRow style={{ borderBottom: '1px solid #ddd', marginBottom: '6px' }}>
                      <IonGrid className='ion-no-padding'>
                        <IonRow className=''>
                          <IonCardTitle className='attractorCard_Title'>Hip</IonCardTitle>
                        </IonRow>
                        <IonRow className='ion-no-padding'>
                          <IonCol size='4' className='ion-no-padding'>
                            <KinematicsGraphV2
                              data={seriesHip.Sagittal.series}
                              subtitle={'Extension (-) / Flexion (+)'}
                              graphAnnotations={tabData.graphAnnotations}
                              strokeWidth={showAllStrides ? 1 : 2}
                              yAxis={seriesHip.Sagittal.yAxis}
                            />
                          </IonCol>
                          <IonCol size='4' className='ion-no-padding'>
                            <KinematicsGraphV2
                              data={seriesHip.Coronal.series}
                              subtitle={'Abduction (-) / Adduction (+)'}
                              graphAnnotations={tabData.graphAnnotations}
                              strokeWidth={showAllStrides ? 1 : 2}
                              yAxis={seriesHip.Coronal.yAxis}
                            />
                          </IonCol>
                          <IonCol size='4' className='ion-no-padding'>
                            <KinematicsGraphV2
                              data={seriesHip.Transversal.series}
                              subtitle={'External Rotation (-) / Internal Rotation (+)'}
                              graphAnnotations={tabData.graphAnnotations}
                              strokeWidth={showAllStrides ? 1 : 2}
                              yAxis={seriesHip.Transversal.yAxis}
                            />
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonRow>

                    <IonRow style={{ borderBottom: '1px solid #ddd', marginBottom: '6px' }}>
                      <IonGrid className='ion-no-padding'>
                        <IonRow className=''>
                          <IonCardTitle className='attractorCard_Title'>Knee</IonCardTitle>
                        </IonRow>
                        <IonRow className='ion-no-padding'>
                          <IonCol size='4' className='ion-no-padding'>
                            <KinematicsGraphV2
                              data={seriesKnee.Sagittal.series}
                              subtitle={'Extension (-) / Flexion (+)'}
                              graphAnnotations={tabData.graphAnnotations}
                              strokeWidth={showAllStrides ? 1 : 2}
                              yAxis={seriesKnee.Sagittal.yAxis}
                            />
                          </IonCol>
                          <IonCol size='4' className='ion-no-padding'>
                            <KinematicsGraphV2
                              data={seriesKnee.Coronal.series}
                              subtitle={'Valgus (-) / Varus (+)'}
                              graphAnnotations={tabData.graphAnnotations}
                              strokeWidth={showAllStrides ? 1 : 2}
                              yAxis={seriesKnee.Coronal.yAxis}
                            />
                          </IonCol>
                          <IonCol size='4' className='ion-no-padding'>
                            <KinematicsGraphV2
                              data={seriesKnee.Transversal.series}
                              subtitle={'External Rotation (-) / Internal Rotation (+)'}
                              graphAnnotations={tabData.graphAnnotations}
                              strokeWidth={showAllStrides ? 1 : 2}
                              yAxis={seriesKnee.Transversal.yAxis}
                            />
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonRow>

                    <IonRow>
                      <IonGrid className='ion-no-padding'>
                        <IonRow className=''>
                          <IonCardTitle className='attractorCard_Title'>Ankle</IonCardTitle>
                        </IonRow>
                        <IonRow>
                          <IonCol size='4' className='ion-no-padding'>
                            <KinematicsGraphV2
                              data={seriesAnkle.Sagittal.series}
                              subtitle={'Plantarflexion (-) / Dorsalflexion (+)'}
                              graphAnnotations={tabData.graphAnnotations}
                              strokeWidth={showAllStrides ? 1 : 2}
                              yAxis={seriesAnkle.Sagittal.yAxis}
                            />
                          </IonCol>
                          <IonCol size='4' className='ion-no-padding'>
                            <KinematicsGraphV2
                              data={seriesAnkle.Coronal.series}
                              subtitle={'Eversion (-) / Inversion (+)'}
                              graphAnnotations={tabData.graphAnnotations}
                              strokeWidth={showAllStrides ? 1 : 2}
                              yAxis={seriesAnkle.Coronal.yAxis}
                            />
                          </IonCol>
                          <IonCol size='4' className='ion-no-padding'>
                            <KinematicsGraphV2
                              data={seriesAnkle.Transversal.series}
                              subtitle={'External Rotation (-) / Internal Rotation (+)'}
                              graphAnnotations={tabData.graphAnnotations}
                              strokeWidth={showAllStrides ? 1 : 2}
                              yAxis={seriesAnkle.Transversal.yAxis}
                            />
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            {/* Tables */}
            <IonRow class='ion-no-padding'>
              <IonCol size='6'>
                <IonCard className='' style={{ margin: 0 }}>
                  <ORYXSmallCardHeader title={'Range of motion'} />

                  <IonCardContent>
                    <IonRow className='ion-padding-vertical full_height ion-align-items-start'>
                      <IonCol size='12' className='ion-no-padding'>
                        <table className='ORYX_Table_noMargin'>
                          <thead>
                            <tr>
                              <th>&nbsp;</th>
                              <th>Left</th>
                              <th>Right</th>
                              <th>Symmetry</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className='ORYX_Table_Head'>
                              <td> Pelvis</td>
                              <td className={tabData.tableClassNames.Pelvis.Left}>{romData[0][0].toFixed(1) + '°'}</td>
                              <td className={tabData.tableClassNames.Pelvis.Right}>{romData[0][1].toFixed(1) + '°'}</td>
                              <td className={tabData.tableClassNames.Pelvis.Symmetry}>
                                {romData[0][2].toFixed(0) + '%'}
                              </td>
                            </tr>
                            <tr className='ORYX_Table_Head'>
                              <td> Hip</td>
                              <td className={tabData.tableClassNames.Hip.Left}>{romData[1][0].toFixed(1) + '°'}</td>
                              <td className={tabData.tableClassNames.Hip.Right}>{romData[1][1].toFixed(1) + '°'}</td>
                              <td className={tabData.tableClassNames.Hip.Symmetry}>{romData[1][2].toFixed(0) + '%'}</td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Indent'>Flexion</td>
                              <td>{romData[2][0].toFixed(1) + '°'}</td>
                              <td>{romData[2][1].toFixed(1) + '°'}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Indent'>Extension</td>
                              <td className={tabData.tableClassNames.Hip.ExtensionLeft}>
                                {romData[3][0].toFixed(1) + '°'}
                              </td>
                              <td className={tabData.tableClassNames.Hip.ExtensionRight}>
                                {romData[3][1].toFixed(1) + '°'}
                              </td>
                              <td></td>
                            </tr>
                            <tr className='ORYX_Table_Head'>
                              <td>Knee</td>
                              <td className={tabData.tableClassNames.Knee.Left}>{romData[4][0].toFixed(1) + '°'}</td>
                              <td className={tabData.tableClassNames.Knee.Right}>{romData[4][1].toFixed(1) + '°'}</td>
                              <td className={tabData.tableClassNames.Knee.Symmetry}>
                                {romData[4][2].toFixed(0) + '%'}
                              </td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Indent'> Stance flexion</td>
                              <td className={tabData.tableClassNames.Knee.stanceFlexionLeft}>
                                {romData[5][0].toFixed(1) + '°'}
                              </td>
                              <td className={tabData.tableClassNames.Knee.stanceFlexionRight}>
                                {romData[5][1].toFixed(1) + '°'}
                              </td>
                              <td></td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Indent'>Varus Valgus</td>
                              <td className={tabData.tableClassNames.Knee.varusValgusLeft}>
                                {romData[6][0].toFixed(1) + '°'}
                              </td>
                              <td className={tabData.tableClassNames.Knee.varusValgusRight}>
                                {romData[6][1].toFixed(1) + '°'}
                              </td>
                              <td></td>
                            </tr>
                            <tr className='ORYX_Table_Head'>
                              <td>Ankle</td>
                              <td className={tabData.tableClassNames.Ankle.Left}>{romData[7][0].toFixed(1) + '°'}</td>
                              <td className={tabData.tableClassNames.Ankle.Right}>{romData[7][1].toFixed(1) + '°'}</td>
                              <td className={tabData.tableClassNames.Ankle.Symmetry}>
                                {romData[7][2].toFixed(0) + '%'}
                              </td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Indent'>Dorsal flexion</td>
                              <td>{romData[8][0].toFixed(1) + '°'}</td>
                              <td>{romData[8][1].toFixed(1) + '°'}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Indent'>Plantar flexion</td>
                              <td>{romData[9][0].toFixed(1) + '°'}</td>
                              <td>{romData[9][1].toFixed(1) + '°'}</td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>

              <IonCol size='6'>
                <IonCard className='' style={{ height: '88%', margin: 0 }}>
                  <ORYXSmallCardHeader title={'Spatio temporal information'} />

                  <IonCardContent>
                    <IonRow className='ion-padding-vertical full_height ion-align-items-start'>
                      <IonCol size='12' className='ion-no-padding'>
                        <table className='ORYX_Table_noMargin'>
                          <thead>
                            <tr>
                              <th>&nbsp;</th>
                              <th>Left</th>
                              <th>Right</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className='ORYX_Table_Head'>
                              <td>Stance phase (Percentage of stride time)</td>
                              <td>{tableData[3][1].toFixed(0) + ' %'}</td>
                              <td>{tableData[4][1].toFixed(0) + ' %'}</td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Indent'>Stance phase (seconds)</td>
                              <td>{tableData[0][1].toFixed(2) + ' s'}</td>
                              <td>{tableData[1][1].toFixed(2) + ' s'}</td>
                            </tr>
                            <tr className='ORYX_Table_Head'>
                              <td>Swing phase (Percentage of stride time)</td>
                              <td>{tableData[3][2].toFixed(0) + ' %'}</td>
                              <td>{tableData[4][2].toFixed(0) + ' %'}</td>
                            </tr>
                            <tr>
                              <td className='ORYX_Table_Indent'>Swing phase (seconds)</td>
                              <td>{tableData[0][2].toFixed(2) + ' s'}</td>
                              <td>{tableData[1][2].toFixed(2) + ' s'}</td>
                            </tr>
                          </tbody>
                        </table>
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonFab slot='fixed' horizontal='end' vertical='bottom'>
            <IonFabButton size='small' color='primary'>
              <IonIcon icon={sliders} size='small' />
            </IonFabButton>
            <IonFabList side='top' className=''>
              <IonFabButton onClick={() => toggleShowAllStrides()} title={'Toggle Mean / All Strides'}>
                <IonIcon icon={toggle}></IonIcon>
              </IonFabButton>
              <IonFabButton title={'Show Graph Legend'} onClick={handleShowToast}>
                <IonIcon icon={colorPalette}></IonIcon>
              </IonFabButton>
            </IonFabList>
          </IonFab>

          <LegendToast
            isOpen={showToast}
            onClose={handleCloseToast}
            duration={3000} // 3 seconds
          />
        </IonContent>
      </IonPage>
    );
  }
};
