/** Report detail page, holding tabs for analysis, kinematics, and overview */
import { IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { OverviewReportTab } from '../../OverviewReportTab';
import {
  RunAttractorsReportTab,
  RunningStatusReportTab,
  RunScissorsReportTab,
  RunVariabilityReportTab,
  RunKinematicsReportTab,
  RunHamstringFunctionReportTab,
} from '../../RunReportV2';
import { useState } from 'react';

// Custom Icons
import ORYX_VariabilityIcon from '../../../../assets/icons/ORYX_VariabilityIcon.svg';
import ORYX_ScissorsIcon from '../../../../assets/icons/ORYX_ScissorsIcon.svg';
import ORYX_AttractorsIcon from '../../../../assets/icons/ORYX_AttractorsIcon.svg';

import ORYX_ReportStatusIcon from '../../../../assets/icons/ORYX_ReportStatusIcon.svg';
import ORYX_KinematicsIcon from '../../../../assets/icons/ORYX_KinematicsIcon.svg';
import ORYX_InformationIcon from '../../../../assets/icons/ORYX_InformationIcon.svg';
import ORYX_HamstringIcon from '../../../../assets/icons/ORYX_HamstringIcon.svg';

//   Custom css
import './index.css';
import { RunReportData } from '../../../../model/reportV2.model';
import { useAuthContext } from '../../../../components/authContext';

interface ReportTab {
  url: string;
  title: string;
  status: string;
  icon: string;
  toShow: string;
  toShowVersion: [number, number, number];
}

const runTabs: ReportTab[] = [
  {
    title: 'Analysis',
    url: `runninganalysis`,
    status: 'active',
    icon: ORYX_ReportStatusIcon,
    toShow: 'All',
    toShowVersion: [2, 1, 10],
  },
  {
    title: 'Running Status',
    url: `runningStatus`,
    status: 'active',
    icon: ORYX_AttractorsIcon,
    toShow: 'All',
    toShowVersion: [2, 1, 10],
  },
  {
    title: 'Scissors',
    url: 'scissors',
    status: 'active',
    icon: ORYX_ScissorsIcon,
    toShow: 'All',
    toShowVersion: [2, 1, 10],
  },
  {
    title: 'Kinematics',
    url: 'kinematics',
    status: 'active',
    icon: ORYX_KinematicsIcon,
    toShow: 'All',
    toShowVersion: [2, 1, 10],
  },
  {
    title: 'Variability',
    url: 'variability',
    status: 'active',
    icon: ORYX_VariabilityIcon,
    toShow: 'All',
    toShowVersion: [2, 1, 10],
  },
  {
    title: 'Hamstring Function',
    url: 'hamstring',
    status: 'active',
    icon: ORYX_HamstringIcon,
    toShow: 'ORYX_Admin',
    toShowVersion: [2, 1, 11],
  },
  {
    title: 'Overview',
    url: 'overview',
    status: 'active',
    icon: ORYX_InformationIcon,
    toShow: 'All',
    toShowVersion: [2, 1, 10],
  },
];

interface RunTabsProps {
  id: string;
  reportData: RunReportData;
}

export const RunTabBar = ({ id, reportData }: RunTabsProps) => {
  const [activeRunTab, setActiveRunTab] = useState('Analysis');
  const role = useAuthContext().state.userState?.role;
  // if (import.meta.env.MODE !== 'production') console.log('data', data);
  // Set custom actions on the tabs
  const handleTabWillChange = (e: any) => {
    setActiveRunTab(e.detail.tab);
  };

  const isVersionAbove2111 = (currentVersion: string, targetVersion: [number, number, number]) => {
    if (!currentVersion || typeof currentVersion !== 'string' || targetVersion.length !== 3) return false;
    const [DBmajor, DBminor, DBpatch] = currentVersion.split('.').map(Number);
    const versionParts = currentVersion.split('.').map(Number);

    if (versionParts.length !== 3) return false;

    for (let i = 0; i < 3; i++) {
      if (versionParts[i] > targetVersion[i]) return true;
      if (versionParts[i] < targetVersion[i]) return false;
    }

    return true;
  };

  return (
    <IonTabs onIonTabsWillChange={handleTabWillChange}>
      <IonRouterOutlet id='main'>
        <Route
          path='/reportsV2/Run/:id/runningStatus'
          render={(props) => {
            return <RunningStatusReportTab {...props} tabData={reportData.runningStatusData} />;
          }}
          exact={true}
        />
        <Route
          path='/reportsV2/Run/:id/runninganalysis'
          render={(props) => {
            return <RunAttractorsReportTab {...props} tabData={reportData.runAttractorData} />;
          }}
          exact={true}
        />
        <Route
          path='/reportsV2/Run/:id/scissors'
          render={(props) => {
            return <RunScissorsReportTab {...props} tabData={reportData.runScissorsData} />;
          }}
          exact={true}
        />
        <Route
          path='/reportsV2/Run/:id/variability'
          render={(props) => {
            return <RunVariabilityReportTab {...props} tabData={reportData.runVariabilityData} />;
          }}
          exact={true}
        />
        <Route
          path='/reportsV2/Run/:id/kinematics'
          render={(props) => {
            return <RunKinematicsReportTab {...props} tabData={reportData.runKinematicData} />;
          }}
          exact={true}
        />
        <Route
          path='/reportsV2/Run/:id/hamstring'
          render={(props) => {
            return <RunHamstringFunctionReportTab {...props} tabData={reportData.runHamstringFunctionData!} />;
          }}
          exact={true}
        />
        <Route
          path='/reportsV2/Run/:id/overview'
          render={(props) => {
            return (
              <OverviewReportTab {...props} id={id} fileInfo={reportData.fileInfo} reportType={reportData.reportType} />
            );
          }}
          exact={true}
        />

        <Route
          path={`/reportsV2/Run/${id}`}
          render={() => <Redirect to={`/reportsV2/Run/${id}/runninganalysis`} />}
          exact={true}
        />
      </IonRouterOutlet>

      <IonTabBar slot='bottom' mode='ios'>
        {runTabs.map((tab, _) => {
          let tabClassNames = '';
          if (tab.status === 'disabled') {
            tabClassNames = 'disabled';
          } else if (location.pathname === tab.url) {
            tabClassNames = 'ion-tab-button_selected';
          }
          const CanShowHamstringFunction = isVersionAbove2111(reportData.version, tab.toShowVersion);

          if (tab.toShow === 'All' || (tab.toShow === role && CanShowHamstringFunction)) {
            return (
              <IonTabButton
                mode='ios'
                tab={tab.title}
                href={`/reportsV2/Run/${id}/${tab.url}`}
                className={activeRunTab === tab.title ? 'ion-tab-button_selected' : ''}
                key={tab.title}>
                <IonIcon icon={tab.icon} />
                <IonLabel>{tab.title}</IonLabel>
              </IonTabButton>
            );
          } else return null;
        })}
      </IonTabBar>
    </IonTabs>
  );
};
