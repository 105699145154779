import { useEffect, useState } from 'react';
import { colorLeft, colorRight } from '../theme/colors';

type legendToastProps = {
  isOpen: boolean;
  onClose: () => void;
  duration?: number;
};

/**
 * LegendToast component displays a toast notification with legend entries.
 *
 * @param {Object} props - The properties object.
 * @param {boolean} props.isOpen - Determines if the toast is open.
 * @param {function} props.onClose - Function to call when the toast closes.
 * @param {number} [props.duration=5000] - Duration in milliseconds before the toast automatically closes.
 *
 * @returns {JSX.Element | null} The rendered LegendToast component or null if not open.
 */
export const LegendToast = ({ isOpen, onClose, duration = 5000 }: legendToastProps) => {
  const [isClosing, setIsClosing] = useState(false);
  useEffect(() => {
    if (isOpen) {
      setIsClosing(false);
      const timer = setTimeout(() => {
        setIsClosing(true);
        setTimeout(onClose, 500);
      }, duration);

      // Clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [isOpen, onClose, duration]);

  if (!isOpen && !isClosing) return null;

  return (
    isOpen && (
      <div className={`legendToast ${isClosing ? 'slide-out' : ''}`}>
        <div className='legendToast_Container' style={{ display: 'flex', flexDirection: 'row' }}>
          <div className='legendToast_Entry'>
            <div className='legend_dot' style={{ backgroundColor: colorLeft }}></div>
            <p className='legend_name'>Left</p>
          </div>
          <div className='legendToast_Entry'>
            <div className='legend_dot' style={{ backgroundColor: colorRight }}></div>
            <p className='legend_name'>Right</p>
          </div>
        </div>
      </div>
    )
  );
};
