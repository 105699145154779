/** The Run Kinematics report tab */
import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
  IonCard,
  IonLoading,
  IonToggle,
  IonCardContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonIcon,
  IonButton,
  IonButtons,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonModal,
  IonToast,
} from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { KinematicsGraph, KinematicsGraphV2 } from '../components/Graphs';
import { useEffect, useMemo, useState } from 'react';
import { ORYXCardHeader, ORYXSmallCardHeader } from '../../../components/componentHeaders';
import { RunKinematicData } from '../../../model/reportV2.model';

import { LegendToast } from '../../../components';

import sliders from '../../../assets/icons/sliders.svg';
import toggle from '../../../assets/icons/toggle-left.svg';
import { colorPalette, informationCircleOutline } from 'ionicons/icons';
import { colorLeft, colorRight } from '../PDFDownload/styles/colors';

type KinematicsReportProps = RouteComponentProps & {
  tabData: RunKinematicData;
};

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const RunKinematicsReportTab = ({ tabData }: KinematicsReportProps) => {
  const [showAllStrides, setShowAllStrides] = useState<boolean>(false);
  const [showToast, setShowToast] = useState(false);

  const handleShowToast = () => {
    setShowToast(true);
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };

  const toggleShowAllStrides = () => {
    setShowAllStrides(!showAllStrides);
    localStorage.setItem('showAllStridesKinematics', !showAllStrides ? 'true' : 'false');
  };

  const seriesPelvis = useMemo(() => {
    return showAllStrides ? tabData.pelvisKinematicData.allStrides : tabData.pelvisKinematicData.mean;
  }, [showAllStrides, tabData]);
  const seriesHip = useMemo(() => {
    return showAllStrides ? tabData.hipKinematicData.allStrides : tabData.hipKinematicData.mean;
  }, [showAllStrides, tabData]);
  const seriesKnee = useMemo(() => {
    return showAllStrides ? tabData.kneeKinematicData.allStrides : tabData.kneeKinematicData.mean;
  }, [showAllStrides, tabData]);
  const seriesAnkle = useMemo(() => {
    return showAllStrides ? tabData.ankleKinematicData.allStrides : tabData.ankleKinematicData.mean;
  }, [showAllStrides, tabData]);

  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <IonPage>
        <IonContent className='ion-padding' color='light'>
          <IonGrid fixed>
            <IonRow className='ion-no-padding'>
              <IonCol size='12' className=''>
                <IonCard className='' style={{ margin: 0 }}>
                  <IonCardHeader className='attractorCard_Header'>
                    <IonGrid fixed className='ion-no-padding'>
                      <IonRow className='ion-no-padding ion-align-items-center attractorCard_Header_Row '>
                        <IonCol size='4' className='ion-text-center'>
                          <IonCardTitle className='attractorCard_Title'>Sagittal</IonCardTitle>
                        </IonCol>
                        <IonCol size='4' className='ion-text-center'>
                          <IonCardTitle className='attractorCard_Title'>Frontal</IonCardTitle>
                        </IonCol>
                        <IonCol size='3' className='ion-text-center'>
                          <IonCardTitle className='attractorCard_Title'>Transversal</IonCardTitle>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonRow
                      style={{ borderBottom: '1px solid #ddd', marginBottom: '6px', backgroundColor: 'transparent' }}>
                      <IonGrid className='ion-no-padding'>
                        <IonRow className=''>
                          <IonCardTitle className='attractorCard_Title'>Pelvis</IonCardTitle>
                        </IonRow>
                        <IonRow className='ion-no-padding'>
                          <IonCol size='4' className='ion-no-padding'>
                            <KinematicsGraphV2
                              data={seriesPelvis.Sagittal.series}
                              subtitle={'Anterior tilt (-) / Posterior tilt (+)'}
                              graphAnnotations={tabData.graphAnnotations}
                              strokeWidth={showAllStrides ? 1 : 2}
                              yAxis={seriesPelvis.Sagittal.yAxis}
                            />
                          </IonCol>
                          <IonCol size='4' className='ion-no-padding'>
                            <KinematicsGraphV2
                              data={seriesPelvis.Coronal.series}
                              subtitle={'Drop (-) / Hike (+)'}
                              graphAnnotations={tabData.graphAnnotations}
                              strokeWidth={showAllStrides ? 1 : 2}
                              yAxis={seriesPelvis.Coronal.yAxis}
                            />
                          </IonCol>
                          <IonCol size='4' className='ion-no-padding'>
                            <KinematicsGraphV2
                              data={seriesPelvis.Transversal.series}
                              subtitle={'Backward Rotation (-) / Forward Rotation (+)'}
                              graphAnnotations={tabData.graphAnnotations}
                              strokeWidth={showAllStrides ? 1 : 2}
                              yAxis={seriesPelvis.Transversal.yAxis}
                            />
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonRow>

                    <IonRow style={{ borderBottom: '1px solid #ddd', marginBottom: '6px' }}>
                      <IonGrid className='ion-no-padding'>
                        <IonRow className=''>
                          <IonCardTitle className='attractorCard_Title'>Hip</IonCardTitle>
                        </IonRow>
                        <IonRow className='ion-no-padding'>
                          <IonCol size='4' className='ion-no-padding'>
                            <KinematicsGraphV2
                              data={seriesHip.Sagittal.series}
                              subtitle={'Extension (-) / Flexion (+)'}
                              graphAnnotations={tabData.graphAnnotations}
                              strokeWidth={showAllStrides ? 1 : 2}
                              yAxis={seriesHip.Sagittal.yAxis}
                            />
                          </IonCol>
                          <IonCol size='4' className='ion-no-padding'>
                            <KinematicsGraphV2
                              data={seriesHip.Coronal.series}
                              subtitle={'Abduction (-) / Adduction (+)'}
                              graphAnnotations={tabData.graphAnnotations}
                              strokeWidth={showAllStrides ? 1 : 2}
                              yAxis={seriesHip.Coronal.yAxis}
                            />
                          </IonCol>
                          <IonCol size='4' className='ion-no-padding'>
                            <KinematicsGraphV2
                              data={seriesHip.Transversal.series}
                              subtitle={'External Rotation (-) / Internal Rotation (+)'}
                              graphAnnotations={tabData.graphAnnotations}
                              strokeWidth={showAllStrides ? 1 : 2}
                              yAxis={seriesHip.Transversal.yAxis}
                            />
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonRow>

                    <IonRow style={{ borderBottom: '1px solid #ddd', marginBottom: '6px' }}>
                      <IonGrid className='ion-no-padding'>
                        <IonRow className=''>
                          <IonCardTitle className='attractorCard_Title'>Knee</IonCardTitle>
                        </IonRow>
                        <IonRow className='ion-no-padding'>
                          <IonCol size='4' className='ion-no-padding'>
                            <KinematicsGraphV2
                              data={seriesKnee.Sagittal.series}
                              subtitle={'Extension (-) / Flexion (+)'}
                              graphAnnotations={tabData.graphAnnotations}
                              strokeWidth={showAllStrides ? 1 : 2}
                              yAxis={seriesKnee.Sagittal.yAxis}
                            />
                          </IonCol>
                          <IonCol size='4' className='ion-no-padding'>
                            <KinematicsGraphV2
                              data={seriesKnee.Coronal.series}
                              subtitle={'Valgus (-) / Varus (+)'}
                              graphAnnotations={tabData.graphAnnotations}
                              strokeWidth={showAllStrides ? 1 : 2}
                              yAxis={seriesKnee.Coronal.yAxis}
                            />
                          </IonCol>
                          <IonCol size='4' className='ion-no-padding'>
                            <KinematicsGraphV2
                              data={seriesKnee.Transversal.series}
                              subtitle={'External Rotation (-) / Internal Rotation (+)'}
                              graphAnnotations={tabData.graphAnnotations}
                              strokeWidth={showAllStrides ? 1 : 2}
                              yAxis={seriesKnee.Transversal.yAxis}
                            />
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonRow>

                    <IonRow>
                      <IonGrid className='ion-no-padding'>
                        <IonRow className=''>
                          <IonCardTitle className='attractorCard_Title'>Ankle</IonCardTitle>
                        </IonRow>
                        <IonRow>
                          <IonCol size='4' className='ion-no-padding'>
                            <KinematicsGraphV2
                              data={seriesAnkle.Sagittal.series}
                              subtitle={'Plantarflexion (-) / Dorsalflexion (+)'}
                              graphAnnotations={tabData.graphAnnotations}
                              strokeWidth={showAllStrides ? 1 : 2}
                              yAxis={seriesAnkle.Sagittal.yAxis}
                            />
                          </IonCol>
                          <IonCol size='4' className='ion-no-padding'>
                            <KinematicsGraphV2
                              data={seriesAnkle.Coronal.series}
                              subtitle={'Eversion (-) / Inversion (+)'}
                              graphAnnotations={tabData.graphAnnotations}
                              strokeWidth={showAllStrides ? 1 : 2}
                              yAxis={seriesAnkle.Coronal.yAxis}
                            />
                          </IonCol>
                          <IonCol size='4' className='ion-no-padding'>
                            <KinematicsGraphV2
                              data={seriesAnkle.Transversal.series}
                              subtitle={'External Rotation (-) / Internal Rotation (+)'}
                              graphAnnotations={tabData.graphAnnotations}
                              strokeWidth={showAllStrides ? 1 : 2}
                              yAxis={seriesAnkle.Transversal.yAxis}
                            />
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
          </IonGrid>

          <IonFab slot='fixed' horizontal='end' vertical='bottom'>
            <IonFabButton size='small' color='primary'>
              <IonIcon icon={sliders} size='small' />
            </IonFabButton>
            <IonFabList side='top' className=''>
              <IonFabButton onClick={() => toggleShowAllStrides()} title={'Toggle Mean / All Strides'}>
                <IonIcon icon={toggle}></IonIcon>
              </IonFabButton>
              <IonFabButton title={'Show Graph Legend'} onClick={handleShowToast}>
                <IonIcon icon={colorPalette}></IonIcon>
              </IonFabButton>
            </IonFabList>
          </IonFab>

          <LegendToast
            isOpen={showToast}
            onClose={handleCloseToast}
            duration={3000} // 5 seconds
          />
        </IonContent>
      </IonPage>
    );
  }
};
