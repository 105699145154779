import React, { useEffect, useMemo, useState } from 'react';
import { IonGrid, IonRow, IonCol, IonIcon, IonButton, IonSpinner, IonButtons } from '@ionic/react';

import { closeCircleOutline, toggle } from 'ionicons/icons';
import { uuid } from '../../../../utilities';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { KneePositionData } from '../../../../model/reportV2.model';

import { colorRight } from '../../../../theme/colors';
import { KneePositionGraph } from '../Graphs';
type KneePositionsModalContentProps = {
  data: KneePositionData;
  setShowZoomModal: any;
};

function createLegend(): any[] {
  const meanEntry = [
    {
      id: uuid(),
      name: 'Mean',
      color: '#FFC700',
    },
  ];

  const allStridesEntry = [
    {
      id: uuid(),
      name: 'All strides',
      color: '#0A2E2C',
    },
  ];

  return [...meanEntry, ...allStridesEntry];
}

export const KneePositionsModalContent = ({ data, setShowZoomModal }: KneePositionsModalContentProps) => {
  const [loading, setLoading] = useState(true);
  const [legend, setLegend] = useState<any[]>([]);
  const [showAllStrides, setShowAllStrides] = useState<boolean>(true);
  const [activeTabKneePositions, setActiveTab] = useState('All strides');
  const [dataLeft, setDataLeft] = useState<any[]>(data.kneePositionsLeft);
  const [dataRight, setDataRight] = useState<any[]>(data.kneePositionsRight);

  const seriesKneePositionsLeft = useMemo(() => {
    return showAllStrides ? data.kneePositionsLeft : data.kneePositionLeft;
  }, [showAllStrides, data]);

  const seriesKneePositionsRight = useMemo(() => {
    return showAllStrides ? data.kneePositionsRight : data.kneePositionRight;
  }, [showAllStrides, data]);

  // Tab change set the active tab and the data for the graphs.
  const tabChange = (name: string) => {
    localStorage.setItem('activeTabKneePositions', name);
    setActiveTab(name);
    if (name === 'All strides') {
      setShowAllStrides(true);
      //   setDataLeft(data.kneePositionsLeft);
      //   setDataRight(data.kneePositionsRight);
    } else {
      setShowAllStrides(false);
      //   setDataLeft(data.kneePositionLeft);
      //   setDataRight(data.kneePositionRight);
    }
  };

  useEffect(() => {
    const activeTabKneePositions = localStorage.getItem('activeTabKneePositions');
    if (activeTabKneePositions) {
      setActiveTab(activeTabKneePositions);
    } else {
      localStorage.setItem('activeTabKneePositions', 'All strides');
      setActiveTab('All strides');
    }
  });

  useEffect(() => {
    setLegend(createLegend());
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [data]);

  const cleanUp = () => {
    localStorage.setItem('activeTabKneePositions', 'All strides');
    setShowAllStrides(true);
    setShowZoomModal({ show: false, graph: '' });
  };

  return (
    <>
      <IonGrid fixed className='Modalcontent-container'>
        <IonRow className='variabilityLegend_Row'>
          <IonCol size='11' className=''>
            <div className='variabilityLegend'>
              {legend.map((element, _) => {
                const color = element.color;
                const iconStyle = {
                  backgroundColor: color,
                };

                return (
                  <div
                    key={element.id}
                    className={
                      activeTabKneePositions === element.name
                        ? 'variabilityLegend_Entry_Selected'
                        : 'variabilityLegend_Entry'
                    }
                    onClick={() => {
                      tabChange(element.name);
                    }}>
                    <div className='legend_dot' style={iconStyle} />

                    <p className='legend_name'>{element.name}</p>
                  </div>
                );
              })}
            </div>
          </IonCol>
          <IonCol size='1' className='ion-align-items-center'>
            <IonButtons className='ion-justify-content-center ion-align-self-center' style={{ height: '100%' }}>
              <IonButton
                slot='icon-only'
                fill='clear'
                className='ORYX_ModalButton_Close_Header'
                onClick={() => cleanUp()}>
                <IonIcon icon={closeCircleOutline} />
              </IonButton>
            </IonButtons>
          </IonCol>
        </IonRow>
        <IonRow className='variabilityGraph_Row ion-align-items-center'>
          {loading && <IonSpinner name='bubbles' className='Modal_Spinner' color='primary' />}
          {!loading && (
            <>
              <IonCol size='6' className='ion-no-padding'>
                <KneePositionGraph
                  data={seriesKneePositionsLeft}
                  title={'Left'}
                  subtitle={`Max Knee Height: ${data.maxKneePositions.col0[0].toFixed(2) + ' cm'}`}
                  strokeWidth={showAllStrides ? 1 : 2}
                />
              </IonCol>
              <IonCol size='6' className='ion-no-padding'>
                <KneePositionGraph
                  data={seriesKneePositionsRight}
                  title={'Right'}
                  subtitle={`Max Knee Height: ${data.maxKneePositions.col0[0].toFixed(2) + ' cm'}`}
                  strokeWidth={showAllStrides ? 1 : 2}
                />
              </IonCol>
            </>
          )}
        </IonRow>
      </IonGrid>
    </>
  );
};
