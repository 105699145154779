import * as React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import './graphCard.css';
import { useEffect } from 'react';
import { IonSpinner } from '@ionic/react';
import { EuclideanGraphAnnotations, KinematicYAxis } from '../../../../model/reportV2.model';

type ProximodistalSequenceGraphProps = {
  data: any;
  title: string;
  graphAnnotations: EuclideanGraphAnnotations[];
  yAxis: KinematicYAxis;
  xAxisStart: number;
};

export const ProximodistalSequenceGraph = ({
  data,
  title,
  graphAnnotations,
  yAxis,
  xAxisStart,
}: ProximodistalSequenceGraphProps) => {
  const [loading, setLoading] = React.useState(true);

  const options: ApexOptions = {
    chart: {
      type: 'line',
      width: '100%',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
      offsetX: -10,
    },
    xaxis: {
      type: 'numeric',
      min: xAxisStart,
      labels: {
        style: {
          fontSize: '8px',
        },
      },
    },
    yaxis: {
      show: true,
      min: yAxis.yMin + 100,
      max: yAxis.yMax + 100,
      tickAmount: yAxis.tickAmount,
      labels: {
        formatter: function (value: number) {
          return value.toFixed(0);
        },
        style: {
          fontSize: '8px',
        },
      },
      axisTicks: {
        show: true,
      },
    },
    grid: {
      show: true,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    title: {
      text: title,
      align: 'center',
      margin: 0,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: 'Montserrat',
        color: '#2066FA',
      },
    },
    subtitle: {
      text: 'Data from Initial Contact',
      align: 'center',
      margin: -10,
      floating: false,
      offsetY: 25,
      style: {
        fontSize: '10px',
        fontFamily: 'Montserrat',
      },
    },
    stroke: {
      width: 2,
      dashArray: [0],
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '9px',
      offsetY: -10,
      markers: {
        width: 8,
        height: 8,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
    },
    annotations: {
      xaxis: graphAnnotations,
    },
    markers: {
      size: [0, 0, 0],
      strokeWidth: 0,
      showNullDataPoints: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [data]);

  return (
    <div className='kinematicsGraph_Wrapper'>
      {!loading ? <Chart options={options} series={data} type='line' /> : <IonSpinner name='bubbles' color='primary' />}
    </div>
  );
};
