import React, { useEffect, useState } from 'react';
import { IonGrid, IonRow, IonCol, IonIcon, IonButton, IonSpinner, IonButtons } from '@ionic/react';

import { closeCircleOutline, toggle } from 'ionicons/icons';
import { uuid } from '../../../../utilities';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { KinematicData, PelvisAngularExcursionGraphAnnotations } from '../../../../model/reportV2.model';

import { colorRight } from '../../../../theme/colors';
type PelvisAngularExcursionModalContentProps = {
  pelvisAngularExcursionGraphAnnotations: PelvisAngularExcursionGraphAnnotations;
  pelvisKinematicData: KinematicData;
  setShowZoomModal: any;
};

function createLegend(crpData: any): any[] {
  const crpLegend = crpData.map((entry: any) => {
    return {
      id: uuid(),
      name: entry.name,
      color: entry.color,
    };
  });

  const allStridesEntry = [
    {
      id: uuid(),
      name: 'All planes',
      color: '#4d4f5c',
    },
  ];

  return [...crpLegend, ...allStridesEntry];
}

export const PelvisAngularExcursionModalContent = ({
  pelvisAngularExcursionGraphAnnotations,
  pelvisKinematicData,
  setShowZoomModal,
}: PelvisAngularExcursionModalContentProps) => {
  const [loading, setLoading] = useState(true);
  const [legend, setLegend] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState('All planes');
  const [dataLeft, setDataLeft] = useState<any[]>(pelvisKinematicData.allStrides.Left.series);
  const [dataRight, setDataRight] = useState<any[]>(pelvisKinematicData.allStrides.Right.series);

  // Tab change set the active tab and the data for the graphs.
  const tabChange = (name: string) => {
    localStorage.setItem('activeTab', name);
    setActiveTab(name);
    if (name === 'All planes') {
      setDataLeft(pelvisKinematicData.allStrides.Left.series);
      setDataRight(pelvisKinematicData.allStrides.Right.series);
    } else {
      setDataLeft([pelvisKinematicData.allStrides.Left.series.find((element: any) => element.name === name)]);
      setDataRight([pelvisKinematicData.allStrides.Right.series.find((element: any) => element.name === name)]);
    }
  };

  useEffect(() => {
    const activeTab = localStorage.getItem('activeTab');
    if (activeTab) {
      setActiveTab(activeTab);
    } else {
      localStorage.setItem('activeTab', 'All planes');
      setActiveTab('All planes');
    }
  });

  useEffect(() => {
    setLegend(createLegend(pelvisKinematicData.allStrides.Left.series));
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [pelvisKinematicData]);

  //#region Graph options
  const options_Left: ApexOptions = {
    chart: {
      type: 'line',
      width: '100%',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
      offsetX: -10,
    },
    xaxis: {
      type: 'numeric',
      labels: {
        style: {
          fontSize: '8px',
        },
      },
    },
    yaxis: {
      show: true,
      min: pelvisKinematicData.allStrides.Left.yAxis.yMin,
      max: pelvisKinematicData.allStrides.Left.yAxis.yMax,
      tickAmount: pelvisKinematicData.allStrides.Left.yAxis.tickAmount,
      labels: {
        formatter: function (value: number) {
          return value.toFixed(0);
        },
        style: {
          fontSize: '8px',
        },
      },
      axisTicks: {
        show: true,
      },
    },
    grid: {
      show: true,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    title: {
      text: 'Left',
      align: 'center',
      margin: 0,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: 'Montserrat',
        color: '#2066FA',
      },
    },
    subtitle: {
      text: 'Anterior tilt / Drop (-) | Posterior tilt / Hike (+)',
      align: 'center',
      margin: -10,
      floating: false,
      offsetY: 25,
      style: {
        fontSize: '10px',
        fontFamily: 'Montserrat',
      },
    },
    stroke: {
      width: 1,
      dashArray: [0, 5],
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '9px',
      offsetY: -10,
      markers: {
        width: 8,
        height: 8,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
    },
    annotations: {
      xaxis: pelvisAngularExcursionGraphAnnotations.Left,
      yaxis: [
        {
          y: 0,
          borderColor: 'red',
        },
      ],
    },
    markers: {
      size: [0, 0, 2],
      strokeWidth: 0,
      showNullDataPoints: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  const options_Right: ApexOptions = {
    chart: {
      type: 'line',
      width: '100%',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
      offsetX: -10,
    },
    xaxis: {
      type: 'numeric',
      labels: {
        style: {
          fontSize: '8px',
        },
      },
    },
    yaxis: {
      show: true,
      min: pelvisKinematicData.allStrides.Left.yAxis.yMin,
      max: pelvisKinematicData.allStrides.Left.yAxis.yMax,
      tickAmount: pelvisKinematicData.allStrides.Left.yAxis.tickAmount,
      labels: {
        formatter: function (value: number) {
          return value.toFixed(0);
        },
        style: {
          fontSize: '8px',
        },
      },
      axisTicks: {
        show: true,
      },
    },
    grid: {
      show: true,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    title: {
      text: 'Right',
      align: 'center',
      margin: 0,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: 'Montserrat',
        color: '#2066FA',
      },
    },
    subtitle: {
      text: 'Anterior tilt / Drop (-) | Posterior tilt / Hike (+)',
      align: 'center',
      margin: -10,
      floating: false,
      offsetY: 25,
      style: {
        fontSize: '10px',
        fontFamily: 'Montserrat',
      },
    },
    stroke: {
      width: 1,
      dashArray: [0, 5],
    },
    fill: {
      colors: ['2066FA', '#829696'],
    },

    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '9px',
      markers: {
        width: 8,
        height: 8,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
    },
    annotations: {
      xaxis: pelvisAngularExcursionGraphAnnotations.Right,
      yaxis: [
        {
          y: 0,
          borderColor: 'red',
        },
      ],
    },
    markers: {
      size: [0, 0, 2],
      strokeWidth: 0,
      showNullDataPoints: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  //#endregion

  const cleanUp = () => {
    localStorage.setItem('activeTab', 'All planes');
    setShowZoomModal({ show: false, graph: '' });
  };

  return (
    <>
      <IonGrid fixed className='Modalcontent-container'>
        <IonRow className='variabilityLegend_Row'>
          <IonCol size='11' className=''>
            <div className='variabilityLegend'>
              {legend.map((element, _) => {
                const color = element.color;
                const iconStyle = {
                  backgroundColor: color,
                };
                const iconStyleRight = {
                  backgroundColor: colorRight,
                };

                if (element.name === 'Sagittal') {
                  return (
                    <div
                      key={element.id}
                      className={
                        activeTab === element.name ? 'variabilityLegend_Entry_Selected' : 'variabilityLegend_Entry'
                      }
                      onClick={() => {
                        tabChange(element.name);
                        console.log('element.name', element.name);
                      }}>
                      <div className='legend_dot' style={iconStyle} />
                      <p className='legend_name'>/</p>
                      <div className='legend_dot' style={iconStyleRight} />
                      <p className='legend_name'>{element.name}</p>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={element.id}
                      className={
                        activeTab === element.name ? 'variabilityLegend_Entry_Selected' : 'variabilityLegend_Entry'
                      }
                      onClick={() => {
                        tabChange(element.name);
                      }}>
                      <div className='legend_dot' style={iconStyle} />

                      <p className='legend_name'>{element.name}</p>
                    </div>
                  );
                }
              })}
            </div>
          </IonCol>
          <IonCol size='1' className='ion-align-items-center'>
            <IonButtons className='ion-justify-content-center ion-align-self-center' style={{ height: '100%' }}>
              <IonButton
                slot='icon-only'
                fill='clear'
                className='ORYX_ModalButton_Close_Header'
                onClick={() => cleanUp()}>
                <IonIcon icon={closeCircleOutline} />
              </IonButton>
            </IonButtons>
          </IonCol>
        </IonRow>
        <IonRow className='variabilityGraph_Row'>
          {loading && <IonSpinner name='bubbles' className='Modal_Spinner' color='primary' />}
          {!loading && (
            <>
              <IonCol size='6' className='ion-no-padding'>
                <Chart options={options_Left} series={dataLeft} type='line' height='90%' width='100%' />
              </IonCol>
              <IonCol size='6' className='ion-no-padding'>
                <Chart options={options_Right} series={dataRight} type='line' height='90%' width='100%' />
              </IonCol>
            </>
          )}
        </IonRow>
      </IonGrid>
    </>
  );
};
