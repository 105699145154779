import { flattenData, toGraphData, toVariabilityData } from '.';
import { PelvisVariabilityData, ThighCoordinationData } from '../../../model/reportV2.model';
import {
  colorCC,
  colorCS,
  colorCT,
  colorSS,
  colorST,
  variabilityAll_Left,
  variabilityAll_Right,
} from '../../../theme/colors';

/**
 * Retrieves the pelvis variability data based on the provided input data and side.
 *
 * @param data - The input data.
 * @param side - The side of the pelvis ('Left' or 'Right').
 * @returns The pelvis variability data.
 */
export function getThighCoordinationData(data: any, side: 'Left' | 'Right', index: number): ThighCoordinationData {
  const overviewColor = side === 'Left' ? variabilityAll_Left : variabilityAll_Right;

  const crpDataThigh = toGraphData(data.crpDataThigh[`crpThigh_${side}`]);
  console.log('crpDataThigh', crpDataThigh);
  const crpSeriesDataThigh = [
    { type: 'line', name: 'Thigh (s) / Thigh (s)', data: flattenData(crpDataThigh), color: colorSS },
  ];

  const crpDataThighC = toGraphData(data.crpDataThigh[`crpThighC_${side}`]);
  const crpSeriesDataThighC = [
    { type: 'line', name: 'Thigh (c) / Thigh (c)', data: flattenData(crpDataThighC), color: colorCC },
  ];

  const coordinativeVariabilityThigh = toVariabilityData(
    data.coordinativeVariability[`coordinativeVariabilityThigh`][`col${index}`],
  );
  const coordinativeVariabilitySeriesThigh = [
    { type: 'area', name: 'Thigh (s) / Thigh (s)', data: coordinativeVariabilityThigh, color: colorSS },
  ];

  const coordinativeVariabilityThighC = toVariabilityData(
    data.coordinativeVariability[`coordinativeVariabilityThigh`][`col${index + 2}`],
  );

  const coordinativeVariabilitySeriesThighC = [
    { type: 'area', name: 'Thigh (c) / Thigh (c)', data: coordinativeVariabilityThighC, color: colorCC },
  ];

  return {
    crpSeriesDataThigh,
    crpSeriesDataThighC,
    coordinativeVariabilitySeriesThigh,
    coordinativeVariabilitySeriesThighC,
  };
}
