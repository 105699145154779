/** Main url routes for the application */
import { IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { GuardedRoute } from './components/authContext/GuardedRoute';
import { GuestRoute } from './components/authContext/GuestRoute';
import { Menu } from './components/menuItems/Menu';
import { LoginPage, NewPasswordPage, ResetPasswordPage } from './pages/Login/Login';
import { ReportDetailPage } from './pages/ReportV1';
import { ReportsPage } from './pages/ReportList';
import { UploadPage } from './pages/Upload';
import { ReportProvider } from './components/reportContext';
import { Dashboard } from './pages/Dashboard';
import { MeasurementsPage } from './pages/Measurements';
import { ORYXTest } from './pages/Measurements/ORYX/ORYX';
import { HardwareProvider } from './components/hardwareContext';
import { useAuthContext } from './components/authContext';
import { SelectNetworkPage } from './pages/Measurements/ORYX/selectNetwork';
import { UserInfoProvider } from './components/userInfoContext';
import { AdminPage } from './pages/Admin';
import { Redirect } from 'react-router';
import { DeviceManagementPage } from './pages/Device Management';
import { ReportDetailPageV2 } from './pages/ReportV2';
import { KBHomePage } from './pages/Knowlegde Base';
import { WalkMeasurementDoc } from './pages/Knowlegde Base/ORYX_Measurements/Walk';
import { KneeStabilityTestPage } from './pages/Measurements/ORYXKneeStability/KneeStabilityTestPage';
import { WalkReportDetailPageV2 } from './pages/ReportV2/WalkReportV2';
import { RunReportDetailPageV2 } from './pages/ReportV2/RunReportV2';
import { SingleLegSquatReportDetailPageV2 } from './pages/ReportV2/SingleLegSquatsReportV2';
import { SquatReportDetailPageV2 } from './pages/ReportV2/SquatsReportV2';
import { PitchingDashboard } from './pages/PitchingAnalysis';
/** Main url routes for the application
 * FirebaseAuthConsumer provides the userId parameter to the sub pages when a user is logged in.
 * If you are not logged in only the home and login page is rendered.
 */
export const Routes = () => {
  const { isAuthorized } = useAuthContext();
  return (
    <IonReactRouter>
      {/* Note: the Nav element is needed for the side menu to know where to display itself */}
      {/* <IonNav id='main' /> */}
      {/* <Menu /> */}
      {!isAuthorized() ? (
        <IonRouterOutlet>
          <GuestRoute path='/'>
            <LoginPage />
          </GuestRoute>
          <GuestRoute path='/login'>
            <LoginPage />
          </GuestRoute>
          <GuestRoute path='/resetPassword'>
            <ResetPasswordPage />
          </GuestRoute>
          <GuestRoute path='/newPassword'>
            <NewPasswordPage />
          </GuestRoute>
        </IonRouterOutlet>
      ) : (
        <IonSplitPane contentId='main'>
          <Menu />
          <IonRouterOutlet id='main'>
            <UserInfoProvider>
              <ReportProvider>
                <GuardedRoute path='/'>
                  <Redirect to='/dashboard' />
                </GuardedRoute>
                <GuardedRoute path='/dashboard'>
                  <Dashboard />
                </GuardedRoute>
                <GuardedRoute path='/admin'>
                  <AdminPage />
                </GuardedRoute>
                <HardwareProvider>
                  {/* Pitching analysis */}
                  <GuardedRoute path='/pitching'>
                    <PitchingDashboard />
                  </GuardedRoute>

                  <GuardedRoute path='/devicemanagement'>
                    <DeviceManagementPage />
                  </GuardedRoute>
                  <GuardedRoute path='/measurements'>
                    <MeasurementsPage />
                  </GuardedRoute>
                  <GuardedRoute path='/measurements/ORYX/:hardwareId'>
                    <ORYXTest />
                  </GuardedRoute>
                  <GuardedRoute path='/measurements/KneeStability/:hardwareId'>
                    <KneeStabilityTestPage />
                  </GuardedRoute>
                  <GuardedRoute path='/measurements/ORYX/selectNetwork'>
                    <SelectNetworkPage />
                  </GuardedRoute>
                </HardwareProvider>
                <GuardedRoute path='/reports'>
                  <ReportsPage />
                </GuardedRoute>
                <GuardedRoute path='/reportsV1/Run/:id'>
                  <ReportDetailPage />
                </GuardedRoute>
                <GuardedRoute path='/reportsV1/Walk/:id'>
                  <ReportDetailPage />
                </GuardedRoute>
                {/* <GuardedRoute path='/reportsV2/:id'>
                  <ReportDetailPageV2 />
                </GuardedRoute> */}
                <GuardedRoute path='/reportsV2/Walk/:id'>
                  <WalkReportDetailPageV2 />
                </GuardedRoute>
                <GuardedRoute path='/reportsV2/Run/:id'>
                  <RunReportDetailPageV2 />
                </GuardedRoute>
                <GuardedRoute path='/reportsV2/Squat/:id'>
                  <SquatReportDetailPageV2 />
                </GuardedRoute>
                <GuardedRoute path='/reportsV2/SingleLegSquat/:id'>
                  <SingleLegSquatReportDetailPageV2 />
                </GuardedRoute>
                <GuardedRoute path='/upload'>
                  <UploadPage />
                </GuardedRoute>

                <GuardedRoute path='/docs'>
                  <KBHomePage />
                </GuardedRoute>
                <GuardedRoute path='/docs/ORYX_Measurements/Walk'>
                  <WalkMeasurementDoc />
                </GuardedRoute>
              </ReportProvider>
            </UserInfoProvider>
          </IonRouterOutlet>
        </IonSplitPane>
      )}
    </IonReactRouter>
  );
};
