/** The Run Kinematics report tab */
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonFabList,
  IonGrid,
  IonIcon,
  IonModal,
  IonPage,
  IonRow,
  IonText,
  IonToggle,
} from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import '../components/Graphs/graphCard.css';

import { RunHamstringFunctionData, RunVariabilityData } from '../../../model/reportV2.model';
import { useMemo, useState } from 'react';
import { ORYXCardHeader } from '../../../components/componentHeaders';
import { add, colorPalette, globe } from 'ionicons/icons';
import sliders from '../../../assets/icons/sliders.svg';
import toggle from '../../../assets/icons/toggle-left.svg';
import {
  KneePositionGraph,
  PelvisFunctionGraph,
  ProximodistalSequenceGraph,
  ThighCoordinationGraph,
} from '../components/Graphs';
import {
  KneePositionsModalContent,
  PelvisAngularExcursionModalContent,
  ProximodistalSequenceModalContent,
} from '../components/Modals';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { VariabilityGraphLegenda } from '../components/CustomGraphComponents';

type RunHamstringFunctionReportTabProps = RouteComponentProps & {
  tabData: RunHamstringFunctionData;
};

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const RunHamstringFunctionReportTab = ({ tabData }: RunHamstringFunctionReportTabProps) => {
  console.log('tabData', tabData);
  const [showAllStrides, setShowAllStrides] = useState<boolean>(true);

  const toggleShowAllStrides = () => {
    setShowAllStrides(!showAllStrides);
    localStorage.setItem('showAllStridesHamstringFunction', !showAllStrides ? 'true' : 'false');
  };

  const seriesPelvisLeft = useMemo(() => {
    return showAllStrides ? tabData.pelvisKinematicData.allStrides.Left : tabData.pelvisKinematicData.mean.Left;
  }, [showAllStrides, tabData]);

  const seriesPelvisRight = useMemo(() => {
    return showAllStrides ? tabData.pelvisKinematicData.allStrides.Right : tabData.pelvisKinematicData.mean.Right;
  }, [showAllStrides, tabData]);

  const seriesKneePositionsLeft = useMemo(() => {
    return showAllStrides ? tabData.kneePositionsData.kneePositionsLeft : tabData.kneePositionsData.kneePositionLeft;
  }, [showAllStrides, tabData]);

  const seriesKneePositionsRight = useMemo(() => {
    return showAllStrides ? tabData.kneePositionsData.kneePositionsRight : tabData.kneePositionsData.kneePositionRight;
  }, [showAllStrides, tabData]);

  const [showZoomModal, setShowZoomModal] = useState({
    show: false,
    graph: '',
  });

  const options: ApexOptions = {
    chart: {
      type: 'line',
      width: '100%',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
      offsetX: -10,
    },
    xaxis: {
      type: 'numeric',
      labels: {
        style: {
          fontSize: '8px',
        },
      },
    },
    yaxis: {
      show: true,
      // min: yAxis.yMin,
      // max: yAxis.yMax,
      // tickAmount: yAxis.tickAmount,
      labels: {
        formatter: function (value: number) {
          return value.toFixed(0);
        },
        style: {
          fontSize: '8px',
        },
      },
      axisTicks: {
        show: true,
      },
    },
    grid: {
      show: true,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    title: {
      text: 'Left',
      align: 'center',
      margin: 0,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: 'Montserrat',
        color: '#2066FA',
      },
    },
    subtitle: {
      text: 'Test',
      align: 'center',
      margin: -10,
      floating: false,
      offsetY: 25,
      style: {
        fontSize: '10px',
        fontFamily: 'Montserrat',
      },
    },
    stroke: {
      width: 2,
      dashArray: [0],
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '9px',
      offsetY: -10,
      markers: {
        width: 8,
        height: 8,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
    },
    annotations: {
      xaxis: tabData.proximoDistalSequenceAnnotations.Left,
    },
    markers: {
      size: [0, 0, 0],
      strokeWidth: 0,
      showNullDataPoints: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <>
        <IonModal
          isOpen={showZoomModal.show}
          className='ORYX_Modal_Large'
          onDidDismiss={() => setShowZoomModal({ show: false, graph: '' })}>
          {showZoomModal.graph === 'Pelvis' && (
            <PelvisAngularExcursionModalContent
              pelvisAngularExcursionGraphAnnotations={tabData.pelvisAngularExcursionGraphAnnotations}
              pelvisKinematicData={tabData.pelvisKinematicData}
              setShowZoomModal={setShowZoomModal}
            />
          )}
          {showZoomModal.graph === 'Knee Positions' && (
            <KneePositionsModalContent data={tabData.kneePositionsData} setShowZoomModal={setShowZoomModal} />
          )}
          {showZoomModal.graph === 'Proximodistal Sequence' && (
            <ProximodistalSequenceModalContent
              EuclideanData={tabData.euclideanNormData}
              proximodistalSequenceData={tabData.proximoDistalSequenceAnnotations}
              setShowZoomModal={setShowZoomModal}
              xAxisLeft={tabData.annotations_Left[3]}
              xAxisRight={tabData.annotations_Right[3]}
            />
          )}
        </IonModal>
        <IonPage>
          <IonContent className='ion-padding' color='light' fullscreen={true}>
            <IonGrid fixed>
              {/* Pelvis Angular Excursion */}
              <IonRow className='ion-no-padding'>
                <IonCol size='12' className=''>
                  <IonCard
                    className=''
                    style={{ margin: 0 }}
                    onClick={() => setShowZoomModal({ show: true, graph: 'Pelvis' })}>
                    <ORYXCardHeader title={'Pelvis Angular Excursion'}></ORYXCardHeader>

                    <IonCardContent>
                      <IonRow>
                        <IonCol size='6' className='ion-no-padding'>
                          <PelvisFunctionGraph
                            data={seriesPelvisLeft.series}
                            title={'Left'}
                            subtitle={'Anterior tilt / Drop (-) | Posterior tilt / Hike (+)'}
                            graphAnnotations={tabData.pelvisAngularExcursionGraphAnnotations.Left}
                            strokeWidth={showAllStrides ? 1 : 2}
                            yAxis={seriesPelvisLeft.yAxis}
                          />
                        </IonCol>
                        <IonCol size='6' className='ion-no-padding'>
                          <PelvisFunctionGraph
                            data={seriesPelvisRight.series}
                            title={'Right'}
                            subtitle={'Anterior tilt / Drop (-) | Posterior tilt / Hike (+)'}
                            graphAnnotations={tabData.pelvisAngularExcursionGraphAnnotations.Right}
                            strokeWidth={showAllStrides ? 1 : 2}
                            yAxis={seriesPelvisRight.yAxis}
                          />
                        </IonCol>
                      </IonRow>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
              {/* Thigh / Thigh Coordination */}
              <IonRow className='ion-no-padding'>
                <IonCol size='12' className=''>
                  <IonCard className='' style={{ margin: 0 }}>
                    <ORYXCardHeader title={'Inter-Thigh Coordination'}></ORYXCardHeader>

                    <IonCardContent>
                      <IonRow>
                        <IonCol size='6' className='ion-no-padding'>
                          <ThighCoordinationGraph
                            crpData={tabData.thighCoordinationData.Left.crpData}
                            coordinativeVariabilityData={tabData.thighCoordinationData.Left.coordinativeVariability}
                            graphAnnotations={tabData.thighCoordinationData.Left.annotations}
                            strokeWidth={2}
                            title={'Left'}
                          />
                        </IonCol>
                        <IonCol size='6' className='ion-no-padding'>
                          <ThighCoordinationGraph
                            crpData={tabData.thighCoordinationData.Right.crpData}
                            coordinativeVariabilityData={tabData.thighCoordinationData.Right.coordinativeVariability}
                            graphAnnotations={tabData.thighCoordinationData.Right.annotations}
                            strokeWidth={2}
                            title={'Right'}
                          />
                        </IonCol>
                      </IonRow>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
              {/* Knee Positions */}
              <IonRow className='ion-no-padding'>
                <IonCol size='12' className=''>
                  <IonCard
                    className=''
                    style={{ margin: 0 }}
                    onClick={() => setShowZoomModal({ show: true, graph: 'Knee Positions' })}>
                    <ORYXCardHeader title={'Knee Positions'}></ORYXCardHeader>

                    <IonCardContent>
                      <IonRow>
                        <IonCol size='6' className='ion-no-padding'>
                          <KneePositionGraph
                            data={seriesKneePositionsLeft}
                            title={'Left'}
                            subtitle={`Max Knee Height: ${tabData.kneePositionsData.maxKneePositions.col0[0].toFixed(2) + ' cm'}`}
                            strokeWidth={showAllStrides ? 1 : 2}
                          />
                        </IonCol>
                        <IonCol size='6' className='ion-no-padding'>
                          <KneePositionGraph
                            data={seriesKneePositionsRight}
                            title={'Right'}
                            subtitle={`Max Knee Height: ${tabData.kneePositionsData.maxKneePositions.col1[0].toFixed(2) + ' cm'}`}
                            strokeWidth={showAllStrides ? 1 : 2}
                          />
                        </IonCol>
                      </IonRow>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
              {/* Proximodistal Sequence */}
              <IonRow className='ion-no-padding'>
                <IonCol size='12' className=''>
                  <IonCard
                    className=''
                    style={{ margin: 0 }}
                    onClick={() => setShowZoomModal({ show: true, graph: 'Proximodistal Sequence' })}>
                    <ORYXCardHeader title={'Proximodistal Sequence'}></ORYXCardHeader>

                    <IonCardContent>
                      <IonRow>
                        <IonCol size='6' className='ion-no-padding'>
                          <ProximodistalSequenceGraph
                            data={tabData.euclideanNormData.mean.Left.series}
                            title={'Left'}
                            graphAnnotations={tabData.proximoDistalSequenceAnnotations.Left}
                            yAxis={tabData.euclideanNormData.mean.Left.yAxis}
                            xAxisStart={tabData.annotations_Left[3]}
                          />
                        </IonCol>
                        <IonCol size='6' className='ion-no-padding'>
                          <ProximodistalSequenceGraph
                            data={tabData.euclideanNormData.mean.Right.series}
                            title={'Right'}
                            graphAnnotations={tabData.proximoDistalSequenceAnnotations.Right}
                            yAxis={tabData.euclideanNormData.mean.Right.yAxis}
                            xAxisStart={tabData.annotations_Right[3]}
                          />
                        </IonCol>
                      </IonRow>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
              {/* Scissors Table */}
              <IonRow className='ion-no-padding'>
                <IonCol size='12' className=''>
                  <IonCard className='' style={{ margin: 0 }}>
                    <ORYXCardHeader title={'Scissors Velocity & Acceleration'}></ORYXCardHeader>

                    <IonCardContent>
                      <IonRow className='ion-justify-content-center'>
                        <IonCol size='10' className='ion-padding'>
                          <table className='ORYX_Table_Scroll' style={{ fontSize: '12px' }}>
                            <thead>
                              <tr>
                                <th>&nbsp;</th>
                                <th>Left</th>
                                <th>Right</th>
                                <th>Symmetry</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className='ORYX_Table_Head'>
                                <td colSpan={4} className='ORYX_Table_Scroll_SectionHeader'>
                                  Scissors
                                </td>
                              </tr>
                              <tr>
                                <td>Average velocity</td>
                                <td>
                                  {tabData.scissorsTableData.scissors.averageVelocity.left +
                                    ' ' +
                                    tabData.scissorsTableData.scissors.averageVelocity.unit}
                                </td>
                                <td>
                                  {tabData.scissorsTableData.scissors.averageVelocity.right +
                                    ' ' +
                                    tabData.scissorsTableData.scissors.averageVelocity.unit}
                                </td>
                                <td>{tabData.scissorsTableData.scissors.averageVelocity.symmetry + ' %'}</td>
                              </tr>
                              <tr className='ORYX_Table_Head'>
                                <td colSpan={4} className='ORYX_Table_Scroll_SectionHeader'>
                                  Average Velocities
                                </td>
                              </tr>
                              <tr className=''>
                                <td>Terminal Swing</td>
                                <td>
                                  {tabData.scissorsTableData.averageVelocities.terminalSwing.left +
                                    ' ' +
                                    tabData.scissorsTableData.averageVelocities.terminalSwing.unit}
                                </td>
                                <td>
                                  {tabData.scissorsTableData.averageVelocities.terminalSwing.right +
                                    ' ' +
                                    tabData.scissorsTableData.averageVelocities.terminalSwing.unit}
                                </td>
                                <td>{tabData.scissorsTableData.averageVelocities.terminalSwing.symmetry + ' %'}</td>
                              </tr>
                              <tr className=''>
                                <td>Stance</td>
                                <td>
                                  {tabData.scissorsTableData.averageVelocities.stance.left +
                                    ' ' +
                                    tabData.scissorsTableData.averageVelocities.stance.unit}
                                </td>
                                <td>
                                  {tabData.scissorsTableData.averageVelocities.stance.right +
                                    ' ' +
                                    tabData.scissorsTableData.averageVelocities.stance.unit}
                                </td>
                                <td>{tabData.scissorsTableData.averageVelocities.stance.symmetry + ' %'}</td>
                              </tr>
                              <tr className='ORYX_Table_Head'>
                                <td colSpan={4} className='ORYX_Table_Scroll_SectionHeader'>
                                  Peak velocities and accelerations
                                </td>
                              </tr>
                              <tr className=''>
                                <td>Velocity Terminal Swing</td>
                                <td>
                                  {tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.terminalSwing
                                    .left +
                                    ' ' +
                                    tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.terminalSwing
                                      .unit}
                                </td>
                                <td>
                                  {tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.terminalSwing
                                    .right +
                                    ' ' +
                                    tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.terminalSwing
                                      .unit}
                                </td>
                                <td>
                                  {tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.terminalSwing
                                    .symmetry + ' %'}
                                </td>
                              </tr>
                              <tr className=''>
                                <td> Velocity Terminal Stance</td>
                                <td>
                                  {tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.stance.left +
                                    ' ' +
                                    tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.stance.unit}
                                </td>
                                <td>
                                  {tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.stance.right +
                                    ' ' +
                                    tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.stance.unit}
                                </td>
                                <td>
                                  {tabData.scissorsTableData.peakVelocitiesAndAccelerations.velocity.stance.symmetry +
                                    ' %'}
                                </td>
                              </tr>
                              <tr className=''>
                                <td>Acceleration Terminal Swing</td>
                                <td>
                                  {tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.terminalSwing
                                    .left +
                                    ' ' +
                                    tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.terminalSwing
                                      .unit}
                                </td>
                                <td>
                                  {tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.terminalSwing
                                    .right +
                                    ' ' +
                                    tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.terminalSwing
                                      .unit}
                                </td>
                                <td>
                                  {tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.terminalSwing
                                    .symmetry + ' %'}
                                </td>
                              </tr>
                              <tr className=''>
                                <td>Acceleration Stance</td>
                                <td>
                                  {tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.stance.left +
                                    ' ' +
                                    tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.stance.unit}
                                </td>
                                <td>
                                  {tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.stance.right +
                                    ' ' +
                                    tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.stance.unit}
                                </td>
                                <td>
                                  {tabData.scissorsTableData.peakVelocitiesAndAccelerations.acceleration.stance
                                    .symmetry + ' %'}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </IonCol>
                      </IonRow>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonGrid>

            <IonFab slot='fixed' horizontal='end' vertical='bottom'>
              <IonFabButton size='small' color='primary'>
                <IonIcon icon={sliders} size='small' />
              </IonFabButton>
              <IonFabList side='top'>
                <IonFabButton onClick={() => toggleShowAllStrides()} title={'Toggle Mean / All Strides'}>
                  <IonIcon icon={toggle}></IonIcon>
                </IonFabButton>
              </IonFabList>
            </IonFab>
          </IonContent>
        </IonPage>
      </>
    );
  }
};
