/** The Run Kinematics report tab */
import { IonCol, IonContent, IonGrid, IonPage, IonRow, IonText } from '@ionic/react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { ProjectionFootPlantGraph, RearPendulum, RespectTheSagittalPlaneGraph } from '../components/Graphs';

import { DashboardCard, ORYXGraphCard } from '../components/Cards';
import { useEffect, useState } from 'react';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';
import { RunningStatusData } from '../../../model/reportV2.model';
import { ORYXSmallCardHeader } from '../../../components/componentHeaders';

type AttractorsReportProps = RouteComponentProps & {
  tabData: RunningStatusData;
};

/** Run kinematics report tab
 * @param report - the full run report info and data
 */
export const RunningStatusReportTab = ({ tabData }: AttractorsReportProps) => {
  const runningStatusData = tabData.runningStatusTableData;
  const romData = tabData.romData;

  const { width, height } = useWindowDimensions();
  const [windowSize, setWindowSize] = useState('small');
  const [projectionFootPlantRowHeight, setProjectionFootPlantRowHeight] = useState(105);

  useEffect(() => {
    if (height != null) {
      if (height > 860) {
        setWindowSize('large');
        setProjectionFootPlantRowHeight(140);
      } else {
        setWindowSize('small');
        setProjectionFootPlantRowHeight(105);
      }
    }
  }, [width]);

  if (!tabData) {
    return <IonText>Data not found.</IonText>;
  } else {
    return (
      <IonPage>
        <IonContent className='ion-padding' color='light'>
          <IonGrid fixed className='full_height'>
            <IonRow className='ion-align-items-center ion-justify-content-center'>
              <IonCol size='4' className=''>
                <ORYXGraphCard
                  title='Pendulum Left'
                  zoomModalContent={tabData.rearPendulumData.rearPendulumZoomModalContent}
                  info={false}
                  zoom={true}>
                  <IonRow>
                    <RearPendulum
                      data={tabData.rearPendulumData.rearPendulumGraphData_Left}
                      TOLocation={tabData.rearPendulumData.rearPendulum_ToeOff_Left}
                      MSwLocation={tabData.rearPendulumData.rearPendulum_MidSwing_Left}
                      stanceData={tabData.rearPendulumData.rearPendulumStance_Left}
                    />
                  </IonRow>
                  <IonRow className=''>
                    <IonCol size='6' className='ion-no-padding border_Right ion-text-center'>
                      <small> &sigma; TO: {tabData.rearPendulumData.rearPendulumVariance_ToeOff_Left}</small>
                    </IonCol>
                    <IonCol size='6' className='ion-no-padding ion-text-center'>
                      <small> &sigma; MSw: {tabData.rearPendulumData.rearPendulumVariance_MidSwing_Left}</small>
                    </IonCol>
                  </IonRow>
                </ORYXGraphCard>
              </IonCol>
              <IonCol size='4' className=''>
                <ORYXGraphCard
                  title='Pendulum Right'
                  zoomModalContent={tabData.rearPendulumData.rearPendulumZoomModalContent}
                  info={false}
                  zoom={true}>
                  <IonRow>
                    <RearPendulum
                      data={tabData.rearPendulumData.rearPendulumGraphData_Right}
                      TOLocation={tabData.rearPendulumData.rearPendulum_ToeOff_Right}
                      MSwLocation={tabData.rearPendulumData.rearPendulum_MidSwing_Right}
                      stanceData={tabData.rearPendulumData.rearPendulumStance_Right}
                    />
                  </IonRow>
                  <IonRow className=''>
                    <IonCol size='6' className='ion-no-padding border_Right ion-text-center'>
                      <small> &sigma; TO: {tabData.rearPendulumData.rearPendulumVariance_ToeOff_Right}</small>
                    </IonCol>
                    <IonCol size='6' className='ion-no-padding ion-text-center'>
                      <small> &sigma; MSw: {tabData.rearPendulumData.rearPendulumVariance_MidSwing_Right}</small>
                    </IonCol>
                  </IonRow>
                </ORYXGraphCard>
              </IonCol>
              <IonCol size='4' className=''>
                <ORYXGraphCard
                  title='Respect the sagittal plane'
                  zoomModalContent={undefined}
                  info={false}
                  zoom={false}>
                  <IonRow>
                    <RespectTheSagittalPlaneGraph
                      data={tabData.respectTheSagittalPlaneSeries}
                      annotations={tabData.annotations}
                    />
                  </IonRow>
                </ORYXGraphCard>
              </IonCol>
            </IonRow>

            <IonRow className='ion-align-items-stretch ion-justify-content-center'>
              <IonCol size='4' className=''>
                <ORYXGraphCard
                  title='Projection footplant - Left'
                  zoomModalContent={undefined}
                  info={false}
                  zoom={false}>
                  <IonRow style={{ height: projectionFootPlantRowHeight }} className='ion-justify-content-center'>
                    <ProjectionFootPlantGraph
                      data={tabData.footplantSeries_Left.footplantAtInitialContactSeries_Left}
                      subtitle='Initial Contact'
                    />
                  </IonRow>
                  <IonRow style={{ height: projectionFootPlantRowHeight }} className='ion-justify-content-center'>
                    <ProjectionFootPlantGraph
                      data={tabData.footplantSeries_Left.footplantAtMidStanceSeries_Left}
                      subtitle='MidStance'
                    />
                  </IonRow>
                  <IonRow style={{ height: projectionFootPlantRowHeight }} className='ion-justify-content-center'>
                    <ProjectionFootPlantGraph
                      data={tabData.footplantSeries_Left.footplantAtHeelOffSeries_Left}
                      subtitle='Heel Off'
                    />
                  </IonRow>
                </ORYXGraphCard>
              </IonCol>
              <IonCol size='4'>
                <ORYXGraphCard
                  title='Projection footplant - Right'
                  zoomModalContent={undefined}
                  info={false}
                  zoom={false}>
                  <IonRow style={{ height: projectionFootPlantRowHeight }} className='ion-justify-content-center'>
                    <ProjectionFootPlantGraph
                      data={tabData.footplantSeries_Right.footplantAtInitialContactSeries_Right}
                      subtitle='Initial Contact'
                    />
                  </IonRow>
                  <IonRow style={{ height: projectionFootPlantRowHeight }} className='ion-justify-content-center'>
                    <ProjectionFootPlantGraph
                      data={tabData.footplantSeries_Right.footplantAtMidStanceSeries_Right}
                      subtitle='MidStance'
                    />
                  </IonRow>
                  <IonRow style={{ height: projectionFootPlantRowHeight }} className='ion-justify-content-center'>
                    <ProjectionFootPlantGraph
                      data={tabData.footplantSeries_Right.footplantAtHeelOffSeries_Right}
                      subtitle='Heel Off'
                    />
                  </IonRow>
                </ORYXGraphCard>
              </IonCol>
              <IonCol size='4' className='' style={{ height: '100%' }}>
                <ORYXGraphCard title='Info' zoomModalContent={undefined} info={false} zoom={false}>
                  <IonRow className='ion-no-padding'>
                    <table className='ORYX_List'>
                      <tbody>
                        <tr>
                          <td>Stride duration</td>
                          <td>{runningStatusData[0][0].toFixed(2) + ' s'}</td>
                        </tr>
                        <tr>
                          <td>Cadence</td>
                          <td colSpan={3}>{(runningStatusData[2][0] / 2).toFixed(0) + ' strides/min'}</td>
                        </tr>
                      </tbody>
                    </table>
                  </IonRow>
                  <IonRow className='ion-no-padding'>
                    <table className='ORYX_Table'>
                      <thead>
                        <tr>
                          <th>&nbsp;</th>
                          <th>Left</th>
                          <th>Right</th>
                          <th>Symmetry</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Ground contact time</td>
                          <td>{runningStatusData[1][0].toFixed(2) + ' s'}</td>
                          <td>{runningStatusData[1][1].toFixed(2) + ' s'}</td>
                          <td>{runningStatusData[1][2].toFixed(2) + ' %'}</td>
                        </tr>
                        <tr>
                          <td>Propulsive velocity</td>
                          <td>{runningStatusData[4][0].toFixed(0) + ' m/s'}</td>
                          <td>{runningStatusData[4][1].toFixed(0) + ' m/s'}</td>
                          <td>{runningStatusData[4][2].toFixed(2) + ' %'}</td>
                        </tr>

                        <tr className='ORYX_Table_Head'>
                          <td> Pelvis</td>
                          <td>{romData[0][0].toFixed(1) + '°'}</td>
                          <td>{romData[0][1].toFixed(1) + '°'}</td>
                          <td>{romData[0][2].toFixed(0) + '%'}</td>
                        </tr>
                        <tr className='ORYX_Table_Head'>
                          <td> Hip</td>
                          <td>{romData[1][0].toFixed(1) + '°'}</td>
                          <td>{romData[1][1].toFixed(1) + '°'}</td>
                          <td>{romData[1][2].toFixed(0) + '%'}</td>
                        </tr>
                        <tr>
                          <td className='ORYX_Table_Indent'>Flexion</td>
                          <td>{romData[2][0].toFixed(1) + '°'}</td>
                          <td>{romData[2][1].toFixed(1) + '°'}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className='ORYX_Table_Indent'>Extension</td>
                          <td>{romData[3][0].toFixed(1) + '°'}</td>
                          <td>{romData[3][1].toFixed(1) + '°'}</td>
                          <td></td>
                        </tr>
                        <tr className='ORYX_Table_Head'>
                          <td>Knee</td>
                          <td>{romData[4][0].toFixed(1) + '°'}</td>
                          <td>{romData[4][1].toFixed(1) + '°'}</td>
                          <td>{romData[4][2].toFixed(0) + '%'}</td>
                        </tr>
                        <tr>
                          <td className='ORYX_Table_Indent'> Stance flexion</td>
                          <td>{romData[5][0].toFixed(1) + '°'}</td>
                          <td>{romData[5][1].toFixed(1) + '°'}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className='ORYX_Table_Indent'>Varus Valgus</td>
                          <td>{romData[6][0].toFixed(1) + '°'}</td>
                          <td>{romData[6][1].toFixed(1) + '°'}</td>
                          <td></td>
                        </tr>
                        <tr className='ORYX_Table_Head'>
                          <td>Ankle</td>
                          <td>{romData[7][0].toFixed(1) + '°'}</td>
                          <td>{romData[7][1].toFixed(1) + '°'}</td>
                          <td>{romData[7][2].toFixed(0) + '%'}</td>
                        </tr>
                        <tr>
                          <td className='ORYX_Table_Indent'>Dorsal flexion</td>
                          <td>{romData[8][0].toFixed(1) + '°'}</td>
                          <td>{romData[8][1].toFixed(1) + '°'}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className='ORYX_Table_Indent'>Plantar flexion</td>
                          <td>{romData[9][0].toFixed(1) + '°'}</td>
                          <td>{romData[9][1].toFixed(1) + '°'}</td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </IonRow>
                </ORYXGraphCard>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }
};
