import { createKinematicYAxis, flattenData, toGraphData, toVariabilityData } from '.';
import { GraphSeriesData, KinematicData, StridesData } from '../../../model/reportV2.model';
import { colorExtra, colorLeft, colorRight } from '../../../theme/colors';

export function createEuclideanGraphData(data: any): KinematicData {
  const hipEuclideanNormData_Mean_Left = data.reportId.euclideanNorms.col0;
  const hipEuclideanNormData_Mean_Right = data.reportId.euclideanNorms.col1;
  const kneeEuclideanNormData_Mean_Left = data.reportId.euclideanNorms.col2;
  const kneeEuclideanNormData_Mean_Right = data.reportId.euclideanNorms.col3;
  const ankleEuclideanNormData_Mean_Left = data.reportId.euclideanNorms.col4;
  const ankleEuclideanNormData_Mean_Right = data.reportId.euclideanNorms.col5;

  const seriesAllData_Left = createAllStridesSeriesData_Euclidean(
    data.euclideanDataLeft.euclideanNormsHip_Left,
    data.euclideanDataLeft.euclideanNormsKnee_Left,
    data.euclideanDataLeft.euclideanNormsAnkle_Left,
  );
  const yAxisAll_Left = createKinematicYAxis(seriesAllData_Left);

  const seriesAllData_Right = createAllStridesSeriesData_Euclidean(
    data.euclideanDataRight.euclideanNormsHip_Right,
    data.euclideanDataRight.euclideanNormsKnee_Right,
    data.euclideanDataRight.euclideanNormsAnkle_Right,
  );
  const yAxisAll_Right = createKinematicYAxis(seriesAllData_Right);

  const allStrides = {
    Left: {
      series: seriesAllData_Left,
      yAxis: yAxisAll_Left,
    },
    Right: {
      series: seriesAllData_Right,
      yAxis: yAxisAll_Right,
    },
  };

  const meanDataLeft_Hip = toVariabilityData(hipEuclideanNormData_Mean_Left);
  const meanDataRight_Hip = toVariabilityData(hipEuclideanNormData_Mean_Right);
  const meanDataLeft_Knee = toVariabilityData(kneeEuclideanNormData_Mean_Left);
  const meanDataRight_Knee = toVariabilityData(kneeEuclideanNormData_Mean_Right);
  const meanDataLeft_Ankle = toVariabilityData(ankleEuclideanNormData_Mean_Left);
  const meanDataRight_Ankle = toVariabilityData(ankleEuclideanNormData_Mean_Right);

  const seriesData_Mean_Left = [
    {
      type: 'line',
      name: 'Hip',
      data: meanDataLeft_Hip,
      color: colorLeft, // Define your colorLeft variable appropriately
    },
    {
      type: 'line',
      name: 'Knee',
      data: meanDataLeft_Knee,
      color: colorRight, // Define your colorRight variable appropriately
    },
    {
      type: 'line',
      name: 'Ankle',
      data: meanDataLeft_Ankle,
      color: colorExtra, // Define your colorExtra variable appropriately
    },
  ];

  const yAxisDataMean_Left = createKinematicYAxis(seriesData_Mean_Left);

  const seriesData_Mean_Right = [
    {
      type: 'line',
      name: 'Hip',
      data: meanDataRight_Hip,
      color: colorLeft, // Define your colorLeft variable appropriately
    },
    {
      type: 'line',
      name: 'Knee',
      data: meanDataRight_Knee,
      color: colorRight, // Define your colorRight variable appropriately
    },
    {
      type: 'line',
      name: 'Ankle',
      data: meanDataRight_Ankle,
      color: colorExtra, // Define your colorExtra variable appropriately
    },
  ];

  const yAxisDataMean_Right = createKinematicYAxis(seriesData_Mean_Right);

  const mean = {
    Left: {
      series: seriesData_Mean_Left,
      yAxis: yAxisDataMean_Left,
    },
    Right: {
      series: seriesData_Mean_Right,
      yAxis: yAxisDataMean_Right,
    },
  };
  const EuclideanData: KinematicData = {
    allStrides,
    mean,
  };

  return EuclideanData;

  // allStrides: { [key: string]: { series: GraphSeriesData[]; yAxis: KinematicYAxis } };
  // mean: { [key: string]: { series: GraphSeriesData[]; yAxis: KinematicYAxis } };
}

export function createAllStridesSeriesData_Euclidean(
  hipData: StridesData,
  kneeData: StridesData,
  ankleData: StridesData,
): GraphSeriesData[] {
  const hip = toGraphData(hipData);
  const seriesHip = [{ type: 'line', name: 'Hip', data: flattenData(hip), color: colorLeft }];
  const knee = toGraphData(kneeData);
  const seriesKnee = [{ type: 'line', name: 'Knee', data: flattenData(knee), color: colorRight }];
  const ankle = toGraphData(ankleData);
  const seriesAnkle = [{ type: 'line', name: 'Ankle', data: flattenData(ankle), color: colorExtra }];

  const series = [...seriesHip, ...seriesKnee, ...seriesAnkle];
  return series;
}
